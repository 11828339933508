import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;

    this.initSelect2(this.element);
  }
  initSelect2(element, options={}) {
    let $element = $(element);
    $element.css('width', '100%');
    let selectParent = $element.parent();
    options.dropdownParent = selectParent;
    options.theme = "bootstrap-5";
    if ($element.data("custom_matcher") == "exclude_punctuation") {
      options.matcher = function (params, data) {
        // If there are no search terms, return all of the data
        if ($.trim(params.term) === '' || typeof params.term === 'undefined') {
          return data;
        }

        // Do not display the item if there is no 'text' property
        if (typeof data.text === 'undefined') {
          return null;
        }

        // `params.term` should be the term that is used for searching
        // `data.text` is the text that is displayed for the data object
        let item_text = data.text.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim().toLowerCase();
        let search_text = params.term.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim().toLowerCase();
        if (item_text.indexOf(search_text) > -1) {
          return data;
        }

        // Return `null` if the term should not be displayed
        return null;
      };
    }
    $element.on('select2:open', e => {
      const select2 = $(e.target).data('select2');
      if (select2 && select2.dropdown && select2.dropdown.$search && select2.dropdown.$search.get(0)) {
        if (!mobileCheck()) {
          select2.dropdown.$search.get(0).focus();
        }
        let search_field = select2.dropdown.$search;
        let parent_container = search_field.parent();
        addLabelToSelect2SearchField(search_field, parent_container);
      }
    });
    $element.select2(options);
    let sel2Data = $element.data('select2');
    if(sel2Data && sel2Data.$container)
    {
      sel2Data.$container.find('*').addClass('needsclick');
      let found_search_field = sel2Data.$container.find('.select2-search__field');
      if (found_search_field && found_search_field.length) {
        let search_field = found_search_field.first();
        let parent_container = search_field.parent();
        addLabelToSelect2SearchField(search_field, parent_container);
      }
    }
    // Fire native change event when item is selected
    $(element).on('select2:select select2:unselect select2:clear', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }
}
