import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refresh"
export default class extends Controller {
  static values = {
    src: String,
    interval: Number,
    maxtime: Number
  }
  connect() {
    this.max_check_timeMS = (new Date()).getTime() + this.maxtimeValue * 1000;
    this.intervalResource = setInterval(() => {
      // console.log("Refresh Time");
      this.element.setAttribute('src', this.srcValue);
      if ((new Date()).getTime() > this.max_check_timeMS) {
        if (this.intervalResource) {
          clearTimeout(this.intervalResource);
          this.intervalResource = undefined;
        }
      }
    }, this.intervalValue * 1000);
  }

  disconnect() {
    if (this.intervalResource) {
      clearTimeout(this.intervalResource);
    }
  }
}
