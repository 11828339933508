import { Controller } from "@hotwired/stimulus"
import ValidateFormController from './validate_form_controller.js'

// Connects to data-controller="account-setup"

var success = false;

export default class extends ValidateFormController {
  connect() {
    setupTermsScrollBox();
    const reset_form = this.reset_form

    if($("#edit_user_form").length){
      this.element.addEventListener('turbo:submit-start', this.accountUpdateValidateForm.bind(this));
      this.element.addEventListener('submit', this.accountUpdateValidateForm.bind(this));
    } else {
      this.element.addEventListener('turbo:submit-start', this.accountSetupValidateForm.bind(this));
      this.element.addEventListener('submit', this.accountSetupValidateForm.bind(this));
    }

    this.element.noValidate = false;
    this.element.addEventListener('change', this.checkChangedInputs.bind(this));

    if($(this.element).data("demoFormFill") === true) {
      setTimeout(function(){
        $("#checking_email_spinner").removeClass('hidden').addClass("alert-success").html('<em class="fa fa-check-circle"></em> '+i18n_string("home.account_setup.this_email_is_good","This email is good")).show();
        $("#user_email").data("email_valid", "yes");
      },50);
      setTimeout(function(){
        let user_pass_field = document.getElementById('user_password');
        user_pass_field.dispatchEvent(new Event('change'));
        let user_confirm_pass_field = document.getElementById('user_password_confirmation');
        user_confirm_pass_field.dispatchEvent(new Event('change'));
      },150);

    } else {
      if ($("#user_email").length && $("#user_email").val() != "") {
        setTimeout(function(){
          let user_email_field = document.getElementById('user_email');
          var event = new Event('input', {
            bubbles: true,
            cancelable: true,
          });
          user_email_field.dispatchEvent(event);
        }, 100);
      }
    }

    this.emailDebouncer = basicDebouncer( 250 );

  }

  // -------------------------------------------------------------- //
  // ---------------------- EMAIL ADDRESS ------------------------- //
  // -------------------------------------------------------------- //

  verify_email(event) {
    this.emailDebouncer(function(){
      var email_val = event.target.value;
      //console.log(email_val);
      if(email_val != undefined && email_val.trim() != "" && email_val.indexOf("@") != -1)
      {
        $("#account_setup_email_error_wrap").hide().find(".alert").html("");
        $("#checking_email_spinner").removeClass("alert-success hidden").addClass("alert-warning").html('<em class="fal fa-circle-notch fa-spin"></em> '+i18n_string("home.account_setup.checking_email","Checking email")).show();
        // Validate that email is not used
        var send_data = {
          email: email_val,
          user_kind: $("#user_kind").val()
        };
        if($("#associate_user_auth").length && (document.disable_associate_existing_account == undefined || !document.disable_associate_existing_account))
        {
          send_data.associate_message = "yes";
        }
        $("#user_email").data("email_valid","no");
        $.ajax({
          url: '/users/check_email_avail',
          data: send_data,
          success: function(data) {
            if(data.available && data.available == "no")
            {
              $("#checking_email_spinner").hide();
              $("#account_setup_email_error_wrap").show().find(".alert").html(data.message);
              if(!$("#account_setup_email_error_wrap").elementVisible(50))
              {
                $("#user_email").focus();
              }
            }
            else
            {
              $("#checking_email_spinner").removeClass("alert-warning hidden").addClass("alert-success").html('<em class="fa fa-check-circle"></em> '+i18n_string("home.account_setup.this_email_is_good","This email is good")).show();
              $("#user_email").data("email_valid","yes");
            }
          },
          type: 'POST'
        });
      } else if(email_val == undefined || email_val.trim() == "") {
        $("#account_setup_email_error_wrap").hide().find(".alert").html("");
      }
    });
  }

  verify_email_update(event) {
    this.emailDebouncer(function(){
      var email_val = event.target.value;
      var current_email = $('#current_email').val()
      console.log(email_val);
      if(email_val != "" && email_val.indexOf("@") != -1 && email_val != current_email )
      {
        $("#account_setup_email_error_wrap").hide().find(".alert").html("");
        $("#checking_email_spinner").removeClass("alert-success hidden").addClass("alert-warning").html('<em class="fal fa-circle-notch fa-spin"></em> '+i18n_string("home.account_setup.checking_email","Checking email")).show();
        // Validate that email is not used
        var send_data = {
          email: email_val,
          user_kind: $("#user_kind").val()
        };
        if($("#associate_user_auth").length && (document.disable_associate_existing_account == undefined || !document.disable_associate_existing_account))
        {
          send_data.associate_message = "yes";
        }
        $("#user_email").data("email_valid","no");
        $.ajax({
          url: '/users/check_email_avail',
          data: send_data,
          success: function(data) {
            if(data.available && data.available == "no")
            {
              $("#checking_email_spinner").hide();
              $("#account_setup_email_error_wrap").show().find(".alert").html(data.message);
              if(!$("#account_setup_email_error_wrap").elementVisible(50))
              {
                $("#user_email").focus();
              }
            }
            else
            {
              $("#checking_email_spinner").removeClass("alert-warning hidden").addClass("alert-success").html('<em class="fa fa-check-circle"></em> '+i18n_string("home.account_setup.this_email_is_good","This email is good")).show();
              $("#user_email").data("email_valid","yes");
            }
          },
          type: 'POST'
        });
      } else {
        $("#checking_email_spinner").hide()
        $("#user_email").data("email_valid","yes")
        $("#account_setup_email_error_wrap").hide()
      }
    });
  }

  // -------------------------------------------------------------- //
  // ------------------------ PASSWORDS --------------------------- //
  // -------------------------------------------------------------- //
  verify_password(event) {
    if (typeof NBP !== 'undefined') {
      var p = event.target.value;
  
      var commonPassword = NBP.isCommonPassword(p);
  
      if (!commonPassword && p.length >= 8) {
        $("#password_requirement_complex").html('<em class="fa fa-fw fa-check text-success"></em>');
      } else {
        $("#password_requirement_complex").html('<em class="fa fa-fw fa-times text-danger"></em>');
      }
      if (p.length >= 8) {
        $("#password_requirement_length").html('<em class="fa fa-fw fa-check text-success"></em>');
      } else {
        $("#password_requirement_length").html('<em class="fa fa-fw fa-times text-danger"></em>');
      }
  
      // password_requirement_length/password_requirement_complex
      if (commonPassword) {
        $('#password_valid').hide();
        $('#password_fail').show();
        success = false;
      } else {
        if (p.length >= 8) {
          $('#password_valid').show();
          $('#password_fail').hide();
          success = true;
        } else {
          $('#password_valid').hide();
          $('#password_fail').show();
          success = false;
        }
      }
    }
  }

  verify_confirm_password(event) {
    var p = event.target.value;

    if(p == $('#user_password').val() && p.length >= 8 && !NBP.isCommonPassword( p )) {
      $('#password_confirm_valid').show();
      $('#password_confirm_fail').hide();
      success = true;
    } else {
      $('#password_confirm_valid').hide();
      $('#password_confirm_fail').show();
      success = false;
    }
  }

  accountSetupValidateForm(event) {
    if($(event.target).attr("submit_pressed") == "yes") {
      event.preventDefault();
      return false;
    }
    if($(event.target).data("demoFormFill") === true) {
      this.demoAutoFillInRegistrationFields();
    }
    let passedStandardValidation = super.validateForm(event);
    if (passedStandardValidation) {
      let passOtherValidation = true;
      let errors = [];
      if ($("#user_email").data("email_valid") != "yes") {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.please_enter_a_valid_email","Please enter a valid email."));
      }
      if (!$("#agree_terms").prop("checked")) {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.please_finish_reading_the_eula","Please finish reading the End User License Agreement before continuing."));
      }
      if ($("#user_sms_phone").val() != "" && $("#invalid_phone").length) {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.the_phone_number_is_not_available","The entered phone number is used by another account."));
      }
      if (!document.password_ok) {
        passOtherValidation = false;
        errors.push("The password does not meet the requirements.");
      }
      if (!document.password_confirm_ok) {
        // Let's recheck it now.  Password generators/autofillers may not trigger the change
        // -- Send Change event
        var user_password_field_event = new Event('change', {
          bubbles: true,
          cancelable: true,
        });
        var user_password_confirm_field = document.getElementById("user_password_confirmation");
        var user_password_field = document.getElementById("user_password");
        user_password_confirm_field.dispatchEvent(user_password_field_event);
        // -- Also manually recheck since the event above is not synchronous
        if (user_password_confirm_field.value == "" || user_password_confirm_field.value != user_password_field.value) {
          passOtherValidation = false;
          errors.push("The password confirmation does not match.");
        }
      }
      if (passOtherValidation) {
        $(event.target).attr("submit_pressed", "yes");
        return true;
      } else {
        doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>');
      }
    }
    event.preventDefault();
    return false;
  }

  accountUpdateValidateForm(event) {
    if($(event.target).attr("submit_pressed") == "yes") {
      return false;
    }
    let passedStandardValidation = super.validateForm(event);
    if (passedStandardValidation) {
      let passOtherValidation = true;
      let errors = [];
      if ($("#user_email").data("email_valid") != "yes" && $("#user_email").val() != $("#current_email").val()) {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.please_enter_a_valid_email","Please enter a valid email."));
      }
      if ($("#user_sms_phone").val() != "" && $("#invalid_phone").length) {
        passOtherValidation = false;
        errors.push(i18n_string("home.account_setup.the_phone_number_is_not_available","The entered phone number is used by another account."));
      }
      if (passOtherValidation) {
        $(event.target).attr("submit_pressed", "yes");
        return true;
      } else {
        doJsAlert('<div class="alert alert-danger">'+removeDuplicates(errors).join('<br />')+'</div>');
      }
    }
    event.preventDefault();
    return false;
  }

  associate_organization_modal(event) {
    event.preventDefault();

    if( $("#user_email").val() ){
      $("#verify_modal_phone").val("");
      $("#verify_modal_kind").val("email");
      $("#verify_modal_email").val($("#user_email").val()).prop("readonly",true);
      $("#associate-verify-login-modal").modal("show");

      this.associate_organization_modal_setup_modal_buttons();
    }
  }

  associate_organization_modal_phone(event) {
    event.preventDefault();
    let phone_code = $('.selected-dial-code').html();
    let phone_num = $("#user_sms_phone").val();
    phone_code = phone_code.replace(/\D/g, '');
    phone_num = phone_num.replace(/\D/g, '');
    let full_phone = phone_code + phone_num;

    if( full_phone.length ){
      $("#verify_modal_email").val("");
      $("#verify_modal_kind").val("phone");
      $("#verify_modal_phone").val(full_phone).prop("readonly",true);
      $("#associate-verify-login-modal").modal("show");

      this.associate_organization_modal_setup_modal_buttons();
    }
  }

  associate_organization_modal_setup_modal_buttons() {
    let controllerRef = this;
    $(".associate-mfa-hide").show();
    $("#verify_password").val("");
    $("#associate_mfa_verify_frame").html("");
    $("#associate-verify-login-process-button").prop("disabled",false);
    if (!$("#associate-verify-login-process-button").data("click_event_added")) {
      $("#associate-verify-login-process-button").data("click_event_added", 1);
      $("#associate-verify-login-process-button").on("click", function(e){
        e.preventDefault();
        var password = $("#verify_password").val();
        if(password != "")
        {
          $("#associate-verify-login-process-button").prop("disabled",true);
          $("#processing_verify_submit").show();
          $("#verify_error_message").hide();
          let email = $("#verify_modal_email").val();
          let phone = $("#verify_modal_phone").val();
          let kind = $("#verify_modal_kind").val();
          let post_url = $("#associate-verify-login-modal-url").val();
          $.ajax({
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            url: post_url,
            data: {
              email: email,
              phone: phone,
              kind: kind,
              password: password
            },
            success: function(dataObj) {
              if (typeof dataObj === "string") {
                dataObj = jQuery.parseJSON(dataObj);
              }
              if(dataObj && dataObj.auth) {
                controllerRef.processSuccessfulAssociateAccount(dataObj);
              } else if (dataObj && dataObj.mfa_verify_url) {
                // Clean up modal
                $(".associate-mfa-hide").hide();
                $("#processing_verify_submit").hide();
                // Load turbo-frame for 2-step verification stuff
                const frame = document.getElementById('associate_mfa_verify_frame');
                frame.src=dataObj.mfa_verify_url;
                frame.reload();
              }
              else
              {
                $("#verify_password").val("");
                if (dataObj && dataObj.error) {
                  $("#verify_error_message").html(dataObj.error);
                }
                $("#verify_error_message").show();
                $("#associate-verify-login-process-button").prop("disabled",false);
                $("#processing_verify_submit").hide();
              }
            },
            type: 'POST'
          });
        }
        else
        {
          $("#verify_password").focus();
        }
      });
      $(document).on('keypress',function(e) {
        if(e.which == 13) {
          if ($("#verify_password").is(":focus")) {
            $("#associate-verify-login-process-button").click();
          }
        }
      });

      document.addEventListener('submit', function(e){
        let $form = $(e.target);
        if ($form.attr('id') != 'associate_mfa_form') {
          return;
        }
        e.preventDefault();

        $("#verify_error_message").hide();
        let code = $form.find("[name='code']").val();
        if (code && code != "") {
          let kind = $("#verify_modal_kind").val();
          $.ajax({
            headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')},
            url: $form.attr('action'),
            data: {
              code: code,
              kind: kind
            },
            success: function (dataObj) {
              if (typeof dataObj === "string") {
                dataObj = jQuery.parseJSON(dataObj);
              }
              if (dataObj && dataObj.auth) {
                controllerRef.processSuccessfulAssociateAccount(dataObj);
              } else {
                if (dataObj && dataObj.error) {
                  $("#verify_error_message").html(dataObj.error);
                }
                $("#verify_error_message").show();
              }
            },
            type: 'POST'
          });
        }
      });

    }
  }

  demoAutoFillInRegistrationFields() {
    $("select:visible").each(function(){
      if ($(this).val() == "" || $(this).val() == "0" || $(this).val() == "0000" || $(this).val() == 0) {
        var $options = $(this).find('option');
        var random = ~~(Math.random() * ($options.length-1))+1;

        $options.eq(random).prop('selected', true);
        //$(this).change();
        this.dispatchEvent(new Event('change'));
      }
    });
  }

  // 2FA
  disable2fa(event) {
    event.preventDefault();
    this.reset_form()

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: '/users/disable_2fa',
      type: 'post',
      success: function(e) {
        if(e.status == 200) {
          $('#button-2fa-holder').html('<p><button class="btn btn-success text-white" id="enable_2fa_button" data-action="click->account-setup-verification#enable2fa">Enable 2-Factor Authentication</button></p>');
          $('#sms_phone_label').addClass('hidden');
          $('#user_sms_phone').removeAttr("disabled");
        }
      }
    });
  }

  enable2fa(event) {
    event.preventDefault();
    this.reset_form()

    $('#enable-2fa-modal').modal('show');
    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: '/users/enable_2fa',
      type: 'post',
      success: function(e) {
        if(e.status == 500) {
          $('#enable-2fa-modal .modal-body').prepend("<p class='text-danger' id='error-message-2fa'>" + e.message + "</p>")
        }
      }
    });
  }

  processSuccessfulAssociateAccount(dataObj) {
    // Set PW flags since already authenticated, and password is now hidden
    document.password_ok = true;
    document.password_confirm_ok = true;
    // Make these fields check/marked as valid for verification :)
    $("#agree_terms").prop("checked", true);
    $("#user_email").data("email_valid", "yes");

    $("#associate_user_auth").val(dataObj.auth);
    // Hide fields not needed for the existing account
    $(".hide-for-existing-account").hide();
    // if($("#user_kind").val() && $("#user_kind").val().indexOf("admin") == -1)
    // {
    $(".show-for-existing-account").show();
    // }
    $("#submit_existing_account").show();

    if (dataObj.message) {
      $("#account_setup_associate_message_general").html(dataObj.message);
    }

    // Prevent email from being change after authentication
    $("#user_email").removeClass("required").prop("required", false).prop("readonly", true);
    $("#user_sms_phone").prop("readonly", true);
    $("#account_setup_email_error_wrap").hide();
    $("#account_setup_phone_error_wrap").hide().find(".alert").html("");
    // Close modal
    $("#associate-verify-login-modal").modal("hide");
  }

  reset_form() {
    $('#verify_code').val('');
    $('#error-message-2fa').remove();
  }

  updatePasswordCheckbox(event){
    if(event.currentTarget.checked) {
      $("#update-password-div").removeClass("hidden");
    } else {
      $("#update-password-div").addClass("hidden");
      $("#current_password").val("");
      $("#user_password").val("");
      $("#user_password_confirmation").val("");
    }
  }

  // -------------------------------------------------------------- //
  // ------------------------ Associations ------------------------ //
  // -------------------------------------------------------------- //
  add_assoc_checkbox_click(event) {
    if(event.currentTarget.checked) {
      $("#add_associations_div").show();
    } else {
      $("#add_associations_div").hide();
      $("#associations_all_select").val('');
    }
  }

  add_custom_assoc(event) {
    event.preventDefault();
    var custom_association_val = $("#add_custom_association").val();
    if (custom_association_val != "") {
      var newOption = new Option(custom_association_val, "custom:"+custom_association_val, false, true);
      $('#associations_all_select').append(newOption).trigger('change');
      $("#add_custom_association").val("");
    }
  }

  check_assoc_select_for_featured(event) {
    // This is not needed currrently -- we do not have any "featured" wbi associations
    // -- if we add any we can update this with javascript to load demographics for those organizations
  }

}
