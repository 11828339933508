import { Controller } from "@hotwired/stimulus"
import ValidateFormController from "./validate_form_controller"

// Connects to data-controller="process-improvement"
export default class extends ValidateFormController {
  static targets = ["options", "template"]
  connect() {
    this.init_option_sortable();

    document.controllerRef = this

    let edit_form = document.getElementById("process-improvement-edit-form");
    if (edit_form) {
      edit_form.addEventListener('change', this.checkChangedInputs);
    }

    if ($(".piq-tags").length) {
      this.setup_tagging();
    }

    // Check if we can close the search toggle boxes
    if(document.getElementById('update_view_filtering_form') && document.getElementById('update_view_filtering_form').length){
      
      // Check default settings for toggle boxes
      if(document.getElementById('answer_search')){
        if(document.getElementById('answer_search').value !== ''){
          document.getElementById('text-search').classList.add('active')
          document.defaultSettings['text_filter'] = true
        }
      }

      if(document.getElementById('auto_tag')){
        if(document.getElementById('auto_tag').value !== ''){
          document.getElementById('auto_tags_filter').classList.add('active')
          document.defaultSettings['auto_tags_filter'] = true
        }
      }

      if(document.getElementById('tag')){
        if(document.getElementById('tag').value !== ''){
          document.getElementById('custom_tags_filter').classList.add('active')
          document.defaultSettings['custom_tags_filter'] = true
        }
      }

      if(document.getElementById('filter_type_campaign') && document.getElementById('filter_type_campaign').checked){
        if(document.getElementById('campaign_id').value !== '' || !document.getElementById('filter_type_campaign').checked){
          document.getElementById('timeframe_filter').classList.add('active')
          document.defaultSettings['timeframe_filter'] = true
        }
      } else if(document.getElementById('filter_type_date').checked) {
        if(document.getElementById('start_date').value !== document.getElementById('start_date_default').value || document.getElementById('end_date').value !== document.getElementById('end_date_default').value){
          document.getElementById('timeframe_filter').classList.add('active')
          document.defaultSettings['timeframe_filter'] = true
        }
      } else if(document.getElementById('filter_type_phase') && document.getElementById('filter_type_phase').checked) {
        if(document.getElementById('phase').value !== '' || !document.getElementById('filter_type_phase').checked){
          document.getElementById('timeframe_filter').classList.add('active')
          document.defaultSettings['timeframe_filter'] = true
        }
      }

      

      // -------------------------------------------------
      
      document.addEventListener('click', function(e) {
        if(e.target != document.getElementById('text-search') && e.target != document.getElementById('answer_search') && e.target != document.getElementById('search-icon')) {
          if(document.getElementById('answer_search') && document.getElementById('answer_search').value === ''){
            document.getElementById('text-search').classList.remove('active')
            document.getElementById('answer_search').placeholder = i18n_string("process_improvement.search","Search")
            document.defaultSettings['text_filter'] = false
          }
        }

        var auto_tag_filter = document.getElementById('auto_tags_filter')
        if(e.target != auto_tag_filter) {
          if(document.getElementById('auto_tag') && document.getElementById('auto_tag').value === '') {
            auto_tag_filter.classList.remove('active')
            document.defaultSettings["auto_tags_filter"] = false
            document.getElementById('auto_tag_label').innerHTML = document.getElementById('auto_tag_label').dataset.label
          }
        }

        var tag_filter = document.getElementById('custom_tags_filter')
        if(e.target != tag_filter) {
          if(document.getElementById('tag') && document.getElementById('tag').value === '') {
            tag_filter.classList.remove('active')
            document.defaultSettings["custom_tags_filter"] = false
            document.getElementById('custom_tag_label').innerHTML = document.getElementById('custom_tag_label').dataset.label
          }
        }

        var date_filter = document.getElementById('timeframe_filter')

        if(e.target != date_filter) {
          if(date_filter.length){
            date_filter.classList.remove('active')
          }
          document.defaultSettings["timeframe_filter"] = false
          document.getElementById('timeframe_label').innerHTML = document.getElementById('timeframe_label').dataset.label
        }
      })

      document.getElementById('reset-filters').classList.add('hidden')

      for (const [key, value] of Object.entries(document.defaultSettings)) {
        if(value == true) {
          document.getElementById('reset-filters').classList.remove('hidden')
        }
      }
    }


    document.addEventListener("turbo:frame-load", this.setupPagination, true);

    // Reload search after manage tags panel has been closed
    if(document.getElementById('search-answers-button')) {
      var modal = document.getElementById('system_modal_popup');
      modal.addEventListener('hidden.bs.modal', function(event){
        document.getElementById('search-answers-button').click()
      });
    }

    this.initializeSelect2(".select2", {})
  }

  add_option(e) {
    e.preventDefault()
    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().getTime())
    this.optionsTarget.insertAdjacentHTML('beforeend', content);
    this.fix_table_widths();
  }

  applyBulkTags(e) {
    if(confirm(i18n_string("process_improvement.are_you_sure_you_want_to_apply_tags","Are you sure you want to apply these tags to all selected answers?"))){
      var question_id = document.getElementById('question_id').value
      var tags = []
      var tag_details = []
      for(var x = 0; x < document.getElementById('tags-bulk_answer').getElementsByTagName('em').length; x++){
        var elem = document.getElementById('tags-bulk_answer').getElementsByTagName('em')[x]
        tags.push(elem.dataset.tag_id)
        tag_details.push([elem.dataset.tag_id, elem.parentElement.innerText])
      }
      
      var answers = []
      for(var x = 0; x < document.getElementsByName('bulk_answer[]').length; x++){
        if(document.getElementsByName('bulk_answer[]')[x].checked) {
          answers.push(document.getElementsByName('bulk_answer[]')[x].value)
        }
      }

      if(answers.length > 0 && tags.length > 0){
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: '/process-improvement/apply_bulk_tags',
          type: 'POST',
          data: {
            answers: answers,
            tags: tags
          },
          success: function(return_data) {

            // Loop through all answers
            answers.forEach((answer) => {
              var existing_tags = []
              for(var i = 0; i < document.getElementById('tags-' + answer).getElementsByTagName('em').length; i++){
                var tag = document.getElementById('tags-' + answer).getElementsByTagName('em')[i]
                existing_tags.push(tag.dataset.tag_id)
              }

              // Visually apply tags to all selected answers, checks if the tag does not already exist and applies it
              tag_details.forEach((tag) => {
                if(!existing_tags.includes(tag[0])) {
                  let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + tag[1] + ' <em class="fa fa-times unassign-tag" data-answer="' + answer + '" data-tag_id="' + tag[0] + '" data-question="' + question_id + '"></em></span>';
                  $('#tags-' + answer).append(tag_html);
                }
              })
            })

            // Clear existing selected bulk tags
            var tags = document.getElementById('tags-bulk_answer').getElementsByTagName('em')
            var selected_tags = Array.from(tags)
            selected_tags.forEach((t) => {
              t.click()
            })

            // Uncheck selected answers
            answers.forEach((answer) => {
              document.querySelectorAll('input[value="' + answer + '"]').forEach((i) => {
                i.click()
              })
            })
          }
        });
      }
    } else {
      e.preventDefault()
    }
  }

  checkBulkTagging(e, validated = false) {
    var checked = 0
    for(var x = 0; x < document.getElementsByName('bulk_answer[]').length; x++){
      if(document.getElementsByName('bulk_answer[]')[x].checked) {
        checked += 1
      }
    }

    if(checked > 1) {
      document.getElementById('apply_bulk_tagging').classList.remove('hidden')
      document.querySelectorAll('.piq-tags').forEach(function(item){
        item.classList.add('hidden')
      })
    } else {
      document.getElementById('apply_bulk_tagging').classList.add('hidden')
      document.querySelectorAll('.piq-tags').forEach(function(item){
        item.classList.remove('hidden')
      })

      if(checked == 0) {
        document.getElementById('select-all-checkbox').checked = false
      }
    }

    if(validated == false) {
      document.getElementById('all_answers_selected').value = false
      document.getElementById('select_all_answers').classList.add('hidden')
      if(checked >= 1) {
        document.getElementById('select-all-checkbox').indeterminate = true
      } else {
        document.getElementById('select-all-checkbox').indeterminate = false
      }
    }
  }


  checkCampaignCount(e) {
    var item = e.currentTarget
    if(item.checked) {
      document.getElementById('campaign_check_overlay').classList.remove("hidden")
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/process-improvement/'+ item.dataset.questionId +'/verify_campaign_count',
        type: 'POST',
        data: {
          campaign_id: item.value,
        },
        success: function(data) {
          if(data.status == 400) {
            alert(data.message)
            item.checked = false
          }
          document.getElementById('campaign_check_overlay').classList.add("hidden")
        }
      })
    }
  }


  clearAllSelected(e) {
    e.preventDefault()
    document.querySelectorAll('.bulk-answer').forEach(function(item){
      item.checked = false
    })

    document.getElementById('select-all-checkbox').checked = false

    this.checkBulkTagging(e)
  }

  clearAnswerText() {
    document.getElementById('answer_search').value = ""
  }

  clearTooltips() {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })
  }

  confirm_delete(e) {
    if (!confirm(i18n_string("process_improvement.are_you_sure_you_want_to_delete_this_question","Are you sure you want to delete this question?  You will not be able to access the answers after you delete this question."))) {
      e.preventDefault();
    }
  }


  deleteTimeframe(e) {
    if (!confirm(i18n_string("process_improvement.delete_timeframe_confirm","Are you sure you want to delete this timeframe?"))) {
      e.preventDefault();
    }
    this.clearTooltips()
  }


  download_answers_click(e) {
    $("#piq-spinner-holder").fadeIn();
    $("#piq-spinner-holder").on('click',function(e){
      $("#piq-spinner-holder").fadeOut();
    });
    setTimeout(function(){
      $("#piq-spinner-holder").fadeOut();
    }, 2000);
    let btn = $(e.currentTarget);
    btn.blur();
  }

  email_template_copy(e){
    e.preventDefault();
    let iframe = document.getElementById('process-improvement-email-template-iframe');
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      let template_html = iframe.contentWindow.document.body.innerHTML;
      let copy_success = copy_to_clipboard(template_html);
      $("#example_email_body_all_btn").blur();
      if(copy_success){
        $("#copy_msg_example_email_body_all").show().delay(3000).fadeOut('fast');
      }
    }
  }

  email_template_download(e){
    let iframe = document.getElementById('process-improvement-email-template-iframe');
    if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
      e.currentTarget.href='data:text/html;charset=UTF-8,'+encodeURIComponent(iframe.contentWindow.document.body.innerHTML);
    }
  }

  fix_table_widths() {
    $("#option_table_body td").each(function () {
      if (!$(this).data("fixed-width")) {
        $(this).css("width", $(this).width());
        $(this).data("fixed-width", 1);
      }
    });
  }


  focusAutoTags(e) {
    var item = e.currentTarget
    item.classList.add('active')
  }


  focusDates(e) {
    var item = e.currentTarget
    item.classList.add('active')
  }

  focusPhase(e) {
    var item = e.currentTarget
    item.classList.add('active')
  }


  focusSearch(e) {
    var item = e.currentTarget
    item.classList.add('active')
    document.getElementById('answer_search').placeholder = i18n_string("process_improvement.example_search","Ex: Compensation / Pay, Workload / Administrative");
  }

  focusTags(e) {
    var item = e.currentTarget
    item.classList.add('active')
  }


  give_feedback_step_one_process(event) {
    event.preventDefault();
    if (this.validate_give_feedback_form(undefined)){
      let have_textarea = false;
      $(".piq-question").each(function(){
        if($(this).prop("tagName").toLowerCase() == "textarea")
        {
          have_textarea = true;
        }
      });
      if(!have_textarea)
      {
        $("#process-improvement-feedback-form").submit();
        return;
      }
      clearJsAlerts();

      // Confirmation view
      if($(".piq-question").length) {
        $(".piq-question").each(function(){
          let $el = $(this);
          let question_id = $el.data("question");
          if($el.is("textarea")) {
            var tastr = ""+$el.val();
            tastr = tastr.replace("\n","<br>");
            $("#confirm-process-improvement-"+question_id).html(tastr);
          } else if($el.is("select")) {
            var option_text = [];
            $el.find("option:selected").each(function(){
              option_text.push($(this).text());
            });
            $("#confirm-process-improvement-"+question_id).html(option_text.join(", "));
          }
        });
      }
      if($(".piq-question-radio").length) {
        let radio_names = [];
        $(".piq-question-radio").each(function(){
          let myname = $(this).attr("name");
          if(radio_names.indexOf(myname) == -1) {
            radio_names.push(myname);
          }
        });
        for(let i=0;i<radio_names.length;i++) {
          let inputname = radio_names[i];
          inputname = inputname.replace("[","\\[");
          inputname = inputname.replace("]","\\]");

          let checked_radio = $('input[name='+ inputname +']:checked');
          let question_id = checked_radio.data("question");
          let the_text = checked_radio.parent().text();
          $("#confirm-process-improvement-"+question_id).html(the_text);

        }
      }

      $("#process-improvement-feedback-step1").hide();
      $("#process-improvement-feedback-step2").show();
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $("#confirm-submit-button-container").offset().top - $("#confirm-submit-button-container").height() + $(window).height()
        }, 100);
        setTimeout(function(){
          $('html, body').stop();
        },201);
      },100);

    }
  }

  give_feedback_step_two_back(event) {
    event.preventDefault();
    $("#process-improvement-feedback-step2").hide();
    $("#process-improvement-feedback-step1").show();
  }

  give_feedback_step_two_process(event) {
    event.preventDefault();
    $("#process-improvement-feedback-form").submit();
  }

  init_option_sortable() {
    if ($("#option_table_body").length) {
      this.fix_table_widths();
      $("#option_table_body").sortable({
        update: function (event, ui) {
          $('#option_table_body tr').each(function (idx) {
            var inputField = $(this).find(".option-order-field");
            $(inputField).val(idx + 1);
          });
        }
      });
    }
  }

  initializeSelect2(selector, options) {
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }

  preview_sample_question(e) {
    e.preventDefault();
    let $thisObj = $(e.currentTarget);
    $thisObj.blur();
    let question_data = $thisObj.data("question");
    if (question_data && question_data.id) {
      $("#add_piq_question_modal_display_question").html(question_data.name);
      $("#add_piq_question_modal_hidden_id").val(question_data.id);
      $("#add_piq_question_modal_example_text").addClass("hidden");
      $("#add_piq_question_modal_example_radio").addClass("hidden");
      $("#piq-preview-add-question-button").addClass("hidden");
      if (!$thisObj.hasClass("preview-only")) {
        $("#piq-preview-add-question-button").removeClass("hidden");
      }
      if (question_data.display_control == "text") {
        $("#add_piq_question_modal_example_text").removeClass("hidden");
      } else if (question_data.display_control == "radio") {
        $("#add_piq_question_modal_example_radio").removeClass("hidden");
        if (question_data.options && question_data.options.length) {
          let html = "";
          for (let i=0; i < question_data.options.length; i++) {
            html += '<div class="checkbox"><label><input type="radio" name="temp_example" value="1" readonly="readonly"> '+question_data.options[i]+'</label></div>';
          }
          $("#add_piq_question_modal_example_radio").html(html);
        }
      }
      $("#add_piq_question_modal").modal("show");
    }
  }

  question_type_change(e) {
    let $thisObj = $(e.currentTarget);
    let newVal = $thisObj.val();
    if (newVal == "select" || newVal == "radio") {
      // Show options
      $("#option_div").removeClass("hidden");
    } else {
      // Hide options
      $("#option_div").addClass("hidden");
    }
    if (newVal == "select") {
      // Show "Allow selection of multiple answers"
      $("#allow-multiple-select-div").removeClass("hidden");
    } else {
      // Hide "Allow"...
      $("#allow-multiple-select-div").addClass("hidden");
      $("#question_multiple").prop("checked", false);
    }

    if (newVal == "radio") {
      // Show "Allow selection of multiple answers"
      $("#allow-range-div").removeClass("hidden");
    } else {
      $("#allow-range-div").addClass("hidden");
    }
  }


  resetFilters() {
    var filters = document.getElementsByClassName('filter-button')
    Array.prototype.slice.call(filters).forEach(function(item){
      item.classList.remove('active')
    })

    if(document.getElementById('answer_search')){
      document.getElementById('answer_search').value = ""
      document.getElementById('answer_search').placeholder = i18n_string("process_improvement.search","Search")
    }


    if(document.getElementById('phase')) {
      document.getElementById('phase').value = ""
    }
    
    if(document.getElementById('campaign')) {
      document.getElementById('campaign').value = ""
      // document.getElementById('campaign')
    }

    if(document.getElementById('auto_tag')) {
      document.getElementById('auto_tag').value = ""
    }

    if(document.getElementById('tag')) {
      document.getElementById('tag').value = ""
    }

    // if(document.getElementById('filter_type_campaign').checked){
    //   document.getElementById('filter_type_campaign').click()
    //   document.getElementById('campaign_id').value = ''
    //   document.getElementById('start_date').value = document.getElementById('start_date_default').value
    //   document.getElementById('end_date').value = document.getElementById('end_date_default').value
    //   document.getElementById('timeframe_filter').classList.remove('active')

    // } else if(document.getElementById('filter_type_phase').checked) {
    //   document.getElementById('filter_type_phase').click()
    //   document.getElementById('start_date').value = document.getElementById('start_date_default').value
    //   document.getElementById('end_date').value = document.getElementById('end_date_default').value
    //   document.getElementById('timeframe_filter').classList.remove('active')
    //   document.getElementById('phase').value = ''

    // } else if(document.getElementById('filter_type_date').checked) {
    //   document.getElementById('filter_type_date').click()
    //   document.getElementById('start_date').value = document.getElementById('start_date_default').value
    //   document.getElementById('end_date').value = document.getElementById('end_date_default').value
    //   document.getElementById('timeframe_filter').classList.remove('active')
    // } else 
    if(document.getElementById('filter_type_all')) {
      document.getElementById('filter_type_all').click()
      document.getElementById('start_date').value = document.getElementById('start_date_default').value
      document.getElementById('end_date').value = document.getElementById('end_date_default').value
      document.getElementById('timeframe_filter').classList.remove('active')
    }

    document.defaultSettings['timeframe_filter'] = false
    document.defaultSettings['auto_tags_filter'] = false
    document.defaultSettings['custom_tags_filter'] = false
    document.defaultSettings['phase_filter'] = false
    document.defaultSettings['text_filter'] = false

    document.getElementById('search-answers-button').click()    
  }

  resetPagination() {
    document.getElementById('pagination_page').value = 1
  }


  searchAnswers(e) {
    if(e.currentTarget.value.length >= 3 || e.currentTarget.value.length == 0){

      this.showLoader()
      setTimeout(function(){
        document.controllerRef.submitSearch()
      }, 300);
    }
  }


  selectAll(e) {
    var checkbox = e.currentTarget
    var selected_page_answers = 0
    document.getElementById('select_all_answers').classList.add('hidden')
    document.querySelectorAll('.bulk-answer').forEach(function(item){
      if(item.checked == true) {
        selected_page_answers += 1;
      }
    })

    if(selected_page_answers > 0) {
      // clear all selected answers
      document.querySelectorAll('.bulk-answer').forEach(function(item){
        item.checked = false
      })

      checkbox.checked = false
    } else {
      document.querySelectorAll('.bulk-answer').forEach(function(item){
        item.checked = true
        selected_page_answers += 1;
      })
      var total_rows = document.getElementById('total_answer_rows').value
      if(total_rows > selected_page_answers){
        document.getElementById('select_all_answers').innerHTML = 'All ' + selected_page_answers + ' responses on this page are selected. <a href="#" data-action="click->process-improvement#selectAllTotal">Select all ' + total_rows + ' responses for this question</a>'
        document.getElementById('select_all_answers').classList.remove('hidden')
      }
    }

    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })

    this.checkBulkTagging(e, true)
  }


  selectAllTotal(e) {
    e.preventDefault()
    var total_rows = document.getElementById('total_answer_rows').value
    document.getElementById('select_all_answers').innerHTML = 'All ' + total_rows + ' responses are selected. <a href="#" data-action="click->process-improvement#clearAllSelected">Clear Selection</a>'
    document.getElementById('all_answers_selected').value = true
  }


  setDate(e) {
    alert("woot")
    // e.preventDefault()
    // alert(e.value)
  }

  setupPagination(e) {
    if(e.target.id == 'report_responses'){
      if(document.getElementById('pagination-holder')) {
        var pagination_items = document.getElementById('pagination-holder').getElementsByTagName('a')
        Array.prototype.slice.call(pagination_items).forEach(function(item){
          item.addEventListener('click', function(e){
            e.preventDefault()
            document.getElementById('pagination_page').value = e.currentTarget.href.split('page=')[1]
            document.getElementById('search-answers-button').click()
          })
        })
      }
    }
  }

  setup_tagging(){
    $('.dropdown.tags-holder').off('show.bs.dropdown').on('show.bs.dropdown', function(){
      // Reset Stuff
      $(this).find('.save-tag-button').hide();
      $(this).find('.new-tag-input-field').val("");

      // Hide tags already added to this answer
      var dropdownItem = $(this);
      var answer_id = $(this).find(".dropdown-toggle").data("answer");
      if(answer_id) {
        dropdownItem.find('.new-tag-list-items > a').each(function(){
          $(this).removeClass("hide-for-search").show();
        });
        $("#tags-"+answer_id).find(".unassign-tag").each(function () {
          var tag_id = $(this).data("tag_id");
          dropdownItem.find('.answer-tag[data-id="'+tag_id+'"]').addClass("hide-for-search").hide();
        });
      }

      $(this).find('.show-all-tags').hide();
      if($(this).find('.new-tag-list-items > a').not(".hide-for-search").length < 8){
        // $(this).find('.show-all-tags').hide();
        // $(this).find(".new-tag-list-items").removeClass("all");
        $(this).find(".new-tag-list-items").addClass("all");
      } else {
        // $(this).find('.show-all-tags').show();
        // $(this).find(".new-tag-list-items").removeClass("all");
        $(this).find(".new-tag-list-items").addClass("all");
      }
    });

    $('.dropdown.tags-holder').off('click').on('click', function(e) {
      let targetElement = $(e.target);
      if (targetElement.hasClass("show-all-tags")) {
        targetElement.closest(".dropdown").find(".new-tag-list-items").addClass("all");
        targetElement.hide();
        e.stopPropagation();
      }
    });

    $(document).off('keyup', '.new-tag-input-field').on('keyup', '.new-tag-input-field', function(){
      var dropdownParent = $(this).closest(".dropdown");
      if($(this).val().length > 0){
        dropdownParent.find(".show-all-tags").hide();
      } else {
        dropdownParent.find(".show-all-tags").show();
      }

      var data = $(this).val().toLowerCase();
      dropdownParent.find('.new-tag-list-items > a').filter(function(){
        var item = $(this).text().toLowerCase().indexOf(data) > -1;
        if($(this).hasClass("hide-for-search"))
        {
          item = false;
        }
        $(this).toggle(item);
      });

      // Check if we have an exact match. If not show the save button
      var exists = false;
      dropdownParent.find('.new-tag-list-items > a:visible').each(function(i){
        if($(this).text().toLowerCase() == data){
          exists = true;
        }
      });

      if(!exists){
        dropdownParent.find('.save-tag-button').show();
      } else {
        dropdownParent.find('.save-tag-button').hide();
      }
    });
    $(document).off('click','.save-tag-button button').on('click','.save-tag-button button', function(e){
      e.preventDefault();
      let item = $(this);
      let answer_id = item.data("answer");
      let question_id = item.data("question");
      let customer_sub_account_id = item.data("customer_sub_account_id");
      let dropdownParent = item.closest(".dropdown");
      
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/process-improvement/'+question_id+'/create-tag',
        type: 'POST',
        data: {
          answer_id: answer_id,
          customer_sub_account_id: customer_sub_account_id,
          name: $("#new_tag_"+answer_id).val()
        },
        success: function(return_data) {
          if (return_data.tag_id) {

            if(return_data.answer_id) {
              // return_data. tag_id/name/answer_id
              let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + return_data.name + ' <em class="fa fa-times unassign-tag" data-answer="' + return_data.answer_id + '" data-tag_id="' + return_data.tag_id + '" data-question="' + question_id + '"></em></span>';
              $('#tags-' + return_data.answer_id).append(tag_html);
              // Add tag to all dropdowns
              let html = '<a class="dropdown-item answer-tag" data-id="' + return_data.tag_id + '" data-question="' + question_id + '" data-tag="' + return_data.name + '" data-answer="%%answer_id%%">' + return_data.name + '</a>';
              $(".new-tag-list-items").each(function () {
                let myhtml = html.replace("%%answer_id%%", $(this).data("answer_id"));
                $(this).append(myhtml);
              });
            } else {
              // return_data. tag_id/name
              // Used for bulk tagging options.
              let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + return_data.name + ' <em class="fa fa-times unassign-tag" data-answer="bulk_answer" data-tag_id="' + return_data.tag_id + '" data-question="' + question_id + '"></em></span>';
              $('#tags-bulk_answer').append(tag_html);
              // Add tag to all dropdowns
              let html = '<a class="dropdown-item answer-tag" data-id="' + return_data.tag_id + '" data-question="' + question_id + '" data-tag="' + return_data.name + '" data-answer="%%answer_id%%">' + return_data.name + '</a>';
              $(".new-tag-list-items").each(function () {
                let myhtml = html.replace("%%answer_id%%", $(this).data("answer_id"));
                $(this).append(myhtml);
              });

              document.getElementById('bulk_answer_submit_holder').classList.remove('hidden')
            }

            $('#tag').append("<option value='" + return_data.tag_id + "' data-name='" + return_data.name + "' data-total='1'>" + return_data.name + "</option>")
            var options = {}
            var parentElement = $('#tag').closest(".select2-wrapper-div");
            if (parentElement.length) {
              options.dropdownParent = parentElement;
            }
            $('#tag').select2(options);
            
          }
        }
      });
    });

    // Assign the tag from the dropdown to the specific answer
    $(document).off('click', '.answer-tag').on('click', '.answer-tag', function(e){
      e.preventDefault();

      let answer_id = $(this).data('answer');
      let tag_id = $(this).data('id');
      let question_id = $(this).data('question');
      let tag_name = $(this).data('tag')

      if(answer_id != 'bulk_answer') {
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: '/process-improvement/'+question_id+'/assign-tag',
          type: 'POST',
          data: {
            answer_id: answer_id,
            tag_id: tag_id
          },
          success: function(return_data) {
            if (return_data.tag_id) {
              // return_data. tag_id/name/answer_id
              let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + return_data.name + ' <em class="fa fa-times unassign-tag" data-answer="' + return_data.answer_id + '" data-tag_id="' + return_data.tag_id + '" data-question="' + question_id + '"></em></span>';
              $('#tags-' + return_data.answer_id).append(tag_html);
            }
          }
        });
      } else {
        let tag_html = ' <span class="badge bg-info mb-1 mt-1">' + tag_name + ' <em class="fa fa-times unassign-tag" data-answer="bulk_answer" data-tag_id="' + tag_id + '" data-question="' + question_id + '"></em></span>';
        $('#tags-bulk_answer').append(tag_html);
        document.getElementById('bulk_answer_submit_holder').classList.remove('hidden')
      }

    });

    // Remove tag from the specific answer
    $(document).off('click', '.unassign-tag').on('click', '.unassign-tag', function(){
      let item = $(this);
      // <em class="fa fa-times unassign-tag" data-answer="<%= answer.id %>" data-tag_id="<%= tag.id %>" data-question="<%= @improvement_question.id %>"></em></span>

      let answer_id = item.data('answer');
      let tag_id = item.data('tag_id');
      let question_id = item.data('question');

      if(answer_id != 'bulk_answer') {
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: '/process-improvement/'+question_id+'/unassign-tag',
          type: 'POST',
          data: {
            answer_id: answer_id,
            tag_id: tag_id
          },
          success: function(return_data) {
            if (return_data && return_data.status == 'success') {
              item.parent().remove();
            }
          }
        });
      } else {
        item.parent().remove();
        if(document.getElementById('tags-bulk_answer').children.length == 0) {
          document.getElementById('bulk_answer_submit_holder').classList.add('hidden')
        }
      }

    });

  }


  showLoader() {
    document.getElementById('report_responses').innerHTML = "<p class='py-5 my-5 text-center'><em class='fal fa-spin fa-circle-notch fa-5x'></em></p>"

    // Hide all popup panels
    var filters = document.getElementsByClassName('dropdown-btn')
    Array.prototype.slice.call(filters).forEach(function(item){
      var dropdown = new bootstrap.Dropdown(item)
      dropdown.hide()
    })
  }


  sortAutoTags(e) {

    var sort = document.getElementById("sort_campaign");
    sort.dataset.direction = "NONE"
    sort.classList.remove('sorted_asc')
    sort.classList.remove('sorted_desc')
    sort.classList.add("unsorted")

    var direction = "NONE"

    var item = e.currentTarget
    if(item.dataset.direction == "ASC") {
      item.dataset.direction = "DESC"
      item.classList.remove('sorted_asc')
      item.classList.add('sorted_desc')
      direction = "DESC"
    } else if(item.dataset.direction == "DESC") {
      item.dataset.direction = "NONE"
      item.classList.remove('sorted_desc')
      item.classList.add('unsorted')
      direction = "NONE"
    } else if(item.dataset.direction == "NONE") {
      item.dataset.direction = "ASC"
      item.classList.remove('unsorted')
      item.classList.add('sorted_asc')
      direction = "ASC"
    }

    document.getElementById("sort_section").value = "auto_tags"
    document.getElementById("sort_direction").value = direction

    document.getElementById('search-answers-button').click()
  }


  sortCampaign(e) {

    var sort = document.getElementById("sort_autotag");
    sort.dataset.direction = "NONE"
    sort.classList.remove('sorted_asc')
    sort.classList.remove('sorted_desc')
    sort.classList.add("unsorted")

    var direction = "NONE"

    var item = e.currentTarget
    if(item.dataset.direction == "ASC") {
      item.dataset.direction = "DESC"
      item.classList.remove('sorted_asc')
      item.classList.add('sorted_desc')
      direction = "DESC"
    } else if(item.dataset.direction == "DESC") {
      item.dataset.direction = "NONE"
      item.classList.remove('sorted_desc')
      item.classList.add('unsorted')
      direction = "NONE"
    } else if(item.dataset.direction == "NONE") {
      item.dataset.direction = "ASC"
      item.classList.remove('unsorted')
      item.classList.add('sorted_asc')
      direction = "ASC"
    }

    document.getElementById("sort_section").value = "campaign_phase"
    document.getElementById("sort_direction").value = direction

    document.getElementById('search-answers-button').click()
  }


  submitSearch() {
    document.getElementById('search-answers-button').click()
  }


  toggle_active(e){
    e.preventDefault();
    let $thisObj = $(e.currentTarget);
    let is_active = $thisObj.data("active") ? 1 : 0;
    let question_id = $thisObj.data("question_id");
    $thisObj.blur();

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: '/process-improvement/'+question_id+'/toggle-active',
      type: 'PATCH',
      data: {
        active: is_active,
        question_id: question_id
      },
      success: function(return_data) {
        if (return_data && return_data.question_id && return_data.active !== undefined) {
          let $status_elem = $(".toggle-active-question[data-question_id='"+return_data.question_id+"']");
          $status_elem.data("active",return_data.active);
          let active_text = i18n_string("process_improvement.active","Active");
          let inactive_text = i18n_string("process_improvement.inactive","Inactive");
          if (return_data.active == 1) {
            $status_elem.html('<span class="text-success"><em class="fas fa-star"></em> '+active_text+'</span>');
          } else {
            $status_elem.html('<span class="text-warning"><em class="fal fa-star"></em> '+inactive_text+'</span>');
          }
        }
        if(return_data && return_data.error)
        {
          alert(return_data.error);
        }
      }
    });
  }

  toggleFilterType(e) {
    var item = e.currentTarget

    if(item.value == 'campaign') {
      document.getElementById('filter_dates').classList.add('hidden')
      document.getElementById('filter_campaigns').classList.remove('hidden')
      document.getElementById('filter_all').classList.add('hidden')
      if(document.getElementById('filter_phases')){
        document.getElementById('filter_phases').classList.add('hidden')
      }
    } else if(item.value == 'date') {
      document.getElementById('filter_dates').classList.remove('hidden')
      document.getElementById('filter_all').classList.add('hidden')
      if(document.getElementById('filter_campaigns')){
        document.getElementById('filter_campaigns').classList.add('hidden')
      }
      if(document.getElementById('filter_phases')){
        document.getElementById('filter_phases').classList.add('hidden')
      }
    } else if(item.value == 'phase') {
      document.getElementById('filter_dates').classList.add('hidden')
      document.getElementById('filter_phases').classList.remove('hidden')
      document.getElementById('filter_all').classList.add('hidden')
      if(document.getElementById('filter_campaigns')){
        document.getElementById('filter_campaigns').classList.add('hidden')
      }
    } else if(item.value == 'all') {
      document.getElementById('filter_all').classList.remove('hidden')
      document.getElementById('filter_dates').classList.add('hidden')
      if(document.getElementById('filter_campaigns')){
        document.getElementById('filter_campaigns').classList.add('hidden')
      }
      if(document.getElementById('filter_phases')){
        document.getElementById('filter_phases').classList.add('hidden')
      }
    }
  }

  // update_filter_click(e) {
  //   // // Reset action if we need to
  //   // if ($("#update_view_filtering_form").data("original-action")) {
  //   //   $("#update_view_filtering_form").attr("action", $("#update_view_filtering_form").data("original-action"));
  //   //   $("#update_view_filtering_form").data("original-action", undefined);
  //   // }
  // }

  updateAutoTag(e) {
    var a = document.getElementById('auto_tag')
    var auto_tag_name = a.options[a.selectedIndex].text
    if(auto_tag_name.length > 22) {
      auto_tag_name = auto_tag_name.substr(0, 22) + "\u2026"
    }
    document.getElementById('auto_tag_label').innerHTML = auto_tag_name
  }


  updateCustomTag(e) {
    var c = document.getElementById('tag')
    var custom_tag_name = c.options[c.selectedIndex].text
    if(custom_tag_name.length > 22) {
      custom_tag_name = custom_tag_name.substr(0, 22) + "\u2026"
    }
    document.getElementById('custom_tag_label').innerHTML = custom_tag_name
  }


  updateTag(e) {
    e.preventDefault()
    var item = e.currentTarget

    var form_data = {
      question_tag: {
        kind: item.dataset.kind,
        question_id: item.dataset.questionId,
        name: document.getElementById('question_tag_name_' + item.dataset.id).value
      }
    }

    if(item.dataset.kind == 'update') {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: '/process-improvement/' + item.dataset.id + '/update_question_tag',
        type: 'POST',
        data: form_data,
        success: function(return_data) {
          //Color row background in HSL space (easier to manipulate fading)
          $('#question_tag_' + item.dataset.id).animate({backgroundColor: '#9fdc6a'}, 300).animate({ backgroundColor: '#FFFFFF'}, 300)
        }
      });
    } else if(item.dataset.kind == 'delete') {
      if(confirm( i18n_string("process_improvement.delete_tag","Are you sure you want to delete this tag?") )) {

        form_data['question_tag']['deleted'] = 1

        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: '/process-improvement/' + item.dataset.id + '/update_question_tag',
          type: 'POST',
          data: form_data,
          success: function(return_data) {
            if(return_data.status == 200) {
              document.getElementById('question_tag_' + item.dataset.id).remove()
            }
          }
        });
      }
    }
  }


  updateTimeframe(e) {
    if(e.currentTarget.dataset.kind == 'campaign') {
      var c = document.getElementById('campaign_id')
      var campaign_name = c.options[c.selectedIndex].text
      document.getElementById('timeframe_label').innerHTML = i18n_string("wellness_snapshot.campaign" + ": " + campaign_name, "Campaign: " + campaign_name)
    } else if(e.currentTarget.dataset.kind == "phase") {
      var p = document.getElementById('phase')
      var phase_name = p.options[p.selectedIndex].dataset.name
      document.getElementById('timeframe_label').innerHTML = i18n_string("process_improvement.phase" + ": " + phase_name,"Phase: " + phase_name)
    } else if(e.currentTarget.dataset.kind == "date-range") {
      document.getElementById('timeframe_label').innerHTML = i18n_string("wellness_journey.date_range","Date Range")
    } else {
      document.getElementById('timeframe_label').innerHTML = document.getElementById('timeframe_label').dataset.label
    }
  }

  validate_give_feedback_form(event) {
    let error_message = [];
    if($(".piq-question").length)
    {
      $(".piq-question").each(function(){
        if($(this).val() == "" || $(this).val() == null)
        {
          let fieildsetObj = $(this).closest(".piq-question-wrapper");
          if(fieildsetObj.data("isrequired") == "yes") {
            let qtext = fieildsetObj.find(".piq-question-name-div").text();
            error_message.push(i18n_string("process_improvement.please_select_an_answer_for","Please select an answer for") + ' &quot;' + qtext + '&quot;')
          }
        }
      });
    }
    if($(".piq-question-radio").length)
    {
      let radio_names = [];
      $(".piq-question-radio").each(function(){
        let myname = $(this).attr("name");
        if(radio_names.indexOf(myname) == -1)
        {
          radio_names.push(myname);
        }
      });
      for(let i=0;i<radio_names.length;i++)
      {
        let inputname = radio_names[i];
        inputname = inputname.replace("[","\\[");
        inputname = inputname.replace("]","\\]");
        if (!$('input[name='+ inputname +']:checked').length) {
          let fieildsetObj = $('input[name='+ inputname +']').closest(".piq-question-wrapper");
          if(fieildsetObj.data("isrequired") == "yes") {
            let qtext = fieildsetObj.find(".piq-question-name-div").text();
            error_message.push(i18n_string("process_improvement.please_select_an_answer_for","Please select an answer for") + ' &quot;' + qtext + '&quot;');
          }
        }

      }
    }
    if(error_message.length > 0) {
      if (event) {
        event.preventDefault();
      }
      let errormsg = error_message.join('<br />');
      doJsAlert('<div class="alert alert-danger">'+errormsg+'</div>');
      return false;
    }
    return true;
  }

  validate_piq_form(event) {
    var container = 'message-container'
    if(document.getElementsByClassName('user_feedback_survey')){
      container = 'custom-message-container'
    }

    let passed_normal_validation = super.validateForm(event, container);
    if (passed_normal_validation) {
      let errormsg = "";
      let question_type = $("#question_display_control").val();
      if(question_type == "select" || question_type == "radio") {
        // option_name_new/option_name
        let opt_count = 0;
        $(".option-name-input").each(function () {
          if ($(this).val() != "") {
            opt_count++;
          }
        });
        if (opt_count <= 1) {
          errormsg += i18n_string("process_improvement.you_must_have_at_least_two_options","You must have at least two options") + "<br />";
        }
      }
      if (errormsg != "") {
        event.preventDefault();
        errormsg = errormsg.substring(0, errormsg.length - 6);
        doJsAlert('<div class="alert alert-danger">'+errormsg+'</div>', container);
        return false;
      }
    }
  }
}
