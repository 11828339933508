import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import {Gauge} from "../src/gauge/gauge"

// Connects to data-controller="gauge"
export default class extends Controller {
  initialize() {
    // This is called once per controller
    window.getCursorPosition = function(canvas, event) {
      var rect = canvas.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      console.log("x: " + (x*2) + " y: " + (y*2));
    };
    window.drawArrowhead = function(ctx, locx, locy, angle, sizex, sizey) {
      var hx = sizex / 2;
      var hy = sizey / 2;

      ctx.translate((locx ), (locy));
      ctx.rotate(angle);
      ctx.translate(-hx,-hy);

      ctx.beginPath();
      ctx.moveTo(0,0);
      ctx.lineTo(0,1*sizey);
      ctx.lineTo(1*sizex,1*hy);
      ctx.closePath();
      ctx.fill();

      ctx.translate(hx,hy);
      ctx.rotate(-angle);
      ctx.translate(-locx,-locy);
    };

    // returns radians
    window.findAngle = function(sx, sy, ex, ey) {
      // make sx and sy at the zero point
      return Math.atan2((ey - sy), (ex - sx));
    }
    window.fillTextMultiLine = function(ctx, text, x, y) {
      var lineHeight = ctx.measureText("M").width * 1.2;
      var lines = text.split("\n");
      for (var i = 0; i < lines.length; ++i) {
        ctx.fillText(lines[i], x, y);
        y += lineHeight;
      }
    }
    window.gauge_list = [];
    window.gauge_first_render_complete = {};

    let gaugeControllerSelf = this;
    // Resize Scroll Listeners
    window.addEventListener('scroll', function(event) {
      gaugeControllerSelf.check_gauges_for_animation();
    });
    window.addEventListener('resize', function(event) {
      gaugeControllerSelf.check_gauges_for_animation();
    });

    // Timeout to check if gauges are all loaded
    setTimeout(this.check_gauge_rendering_after_inits, 500, gaugeControllerSelf);

    // Setup modal
    setupBootstrapModal("wbi-score-graph-modal", "Chart", "", '<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">' +  I18n.general.close + '</button>', 'body', 'modal-custom-score-graph');

    $(document).on('click', '.wbi-score-graph-link', function (event){
      event.preventDefault();
      var linkObj = $(this);
      var footnote = linkObj.data('footnote');
      var footnote_gobal = $("#wbi-score-footnote-global").html();
      var modalObj = $("#wbi-score-graph-modal");
      if(linkObj.data("gauge_config"))
      {
        var canvasObj = linkObj.find("canvas");
        var title = canvasObj.attr("alt");
        var position = linkObj.data("position");
        var gauge_config = linkObj.data("gauge_config");
        var bodyHTML = '';
        bodyHTML += '<div class="gauge-wrapper placeholder-glow"><span class="placeholder placeholder-box-4"></span></div>';
        bodyHTML += '<div class="info-text">'+footnote+'<br /><br />'+footnote_gobal+'</div>';

        modalObj.find('.modal-title').text(title);
        modalObj.find('.modal-body').html(bodyHTML);

        modalObj.data("gaugePosition",position);
        modalObj.data("gaugeConfig",gauge_config);

        modalObj.modal('show');
      }
    });
    $(document).on("shown.bs.modal", "#wbi-score-graph-modal", function (event) {
      let modalObj = $(this);
      let gauge_config = modalObj.data("gaugeConfig");
      let position = modalObj.data("gaugePosition");
      // console.log("shown.bs.modal:"+gauge_config+" - "+position);
      if(gauge_config && position)
      {
        modalObj.find('.gauge-wrapper').html('<canvas id="gauge_image_modal" class="gauge-canvas" data-controller="gauge" data-position="'+position+'" data-gauge_config="'+gauge_config+'" data-animate_now="yes" width="600" height="300"></canvas>');
      }
    });
  }

  check_gauges_for_animation(){
    if(gauge_list && gauge_list.length)
    {
      for(var i=0;i<gauge_list.length;i++)
      {
        if(gauge_list[i].animationDone){
          continue;
        }
        var gaugeJQObj = $("#"+gauge_list[i].elementId);
        if(gaugeJQObj.length)
        {
          if(gaugeJQObj.visible(80))
          {
            var configData = gaugeJQObj.data("configData");
            if(configData && configData.c_max_val)
            {
              gauge_list[i].animationDone = true;
              gauge_list[i].gauge.set(configData.c_max_val-gauge_list[i].position);
            }
          }
        }
      }
    }
  }

  check_gauge_rendering_after_inits(gaugeControllerObj){
    let gaugesReady = 0;
    if(gauge_list && gauge_list.length)
    {
      for(var i=0;i<gauge_list.length;i++)
      {
        if(gauge_first_render_complete[gauge_list[i].elementId])
        {
          gaugesReady++;
        }
      }
    }
    if(gaugesReady == gauge_list.length)
    {
      gaugeControllerObj.check_gauges_for_animation()
    }
    else
    {
      setTimeout(this.check_gauge_rendering_after_inits, 500, gaugeControllerObj);
    }
  }

  async connect() {
    // This is called for every connected DOM
    this.element[this.identifier] = this

    let elementId = this.element.id;

    //console.log("Gauge Connect : "+elementId);

    if (!this.element.dataset || !this.element.dataset.gauge_config || !this.element.dataset.position) {
      //console.log("I'm missing something");
      return;
    }
    let gauge_config_url = this.element.dataset.gauge_config;
    let position = this.element.dataset.position;
    let animate_now = this.element.dataset.animate_now == "yes";

    const request = new FetchRequest("get", gauge_config_url, { responseKind: "json"});
    const response = await request.perform();
    if (response.ok) {
      response.json.then(function(configData){
        configData.renderBefore = function(ctx){
          var devicePixelRatio = window.devicePixelRatio || 1;
          var backingStorePixelRatio = (ctx.webkitBackingStorePixelRatio || ctx.mozBackingStorePixelRatio || ctx.msBackingStorePixelRatio || ctx.oBackingStorePixelRatio || ctx.backingStorePixelRatio || 1)
          var displayScale = devicePixelRatio / backingStorePixelRatio;
          var multipler = displayScale/2;
          // console.log("displayScale="+displayScale+" - multipler="+multipler+" - width="+ctx.canvas.clientWidth);

          var textDisplayScale = 1;
          var canvas_width = ctx.canvas.style.width;
          if(canvas_width) {
            var width = parseFloat(canvas_width.replace("px", ""));
            textDisplayScale = width/600;
          }

          // var displayScale = this.gauge.displayScale;
          if(this.c_render && this.c_render.length)
          {
            for(var i=0;i<this.c_render.length;i++)
            {
              switch (this.c_render[i].type) {
                case "font":
                  var fontString = this.c_render[i].value;
                  if(displayScale < 2) {
                    var fontParts = fontString.split("px");
                    var adjustedFontSize = Number(fontParts[0] ) * multipler * textDisplayScale;
                    fontString = (adjustedFontSize+(adjustedFontSize/10)) + "px " + fontParts[1];
                  } else if(displayScale > 2) {
                    var fontParts = fontString.split("px");
                    var adjustedFontSize = Number(fontParts[0] ) * multipler * textDisplayScale;
                    fontString = adjustedFontSize + "px " + fontParts[1];
                  } else {
                    var fontParts = fontString.split("px");
                    var adjustedFontSize = Number(fontParts[0] ) * textDisplayScale;
                    fontString = adjustedFontSize + "px " + fontParts[1];
                  }
                  ctx.font=fontString;
                  break;
                case "fillText":
                  ctx.fillText(this.c_render[i].value,this.c_render[i].x*multipler*textDisplayScale, this.c_render[i].y*multipler*textDisplayScale);
                  break;
                case "lineWidth":
                  if(displayScale < 2) {
                    ctx.lineWidth = this.c_render[i].value * textDisplayScale;
                  } else {
                    ctx.lineWidth = this.c_render[i].value * multipler * textDisplayScale;
                  }
                  break;
                case "strokeStyle":
                  ctx.strokeStyle=this.c_render[i].value;
                  break;
                case "beginPath":
                  ctx.beginPath();
                  break;
                case "moveTo":
                  ctx.moveTo(this.c_render[i].x*multipler * textDisplayScale, this.c_render[i].y*multipler*textDisplayScale);
                  break;
                case "quadraticCurveTo":
                  ctx.quadraticCurveTo(this.c_render[i].x1*multipler * textDisplayScale, this.c_render[i].y1*multipler*textDisplayScale, this.c_render[i].x2*multipler*textDisplayScale, this.c_render[i].y2*multipler*textDisplayScale);
                  break;
                case "stroke":
                  ctx.stroke();
                  break;
                case "drawArrowhead":
                  // if(displayScale < 2) {
                  //   drawArrowhead(ctx, this.c_render[i].x2 * multipler * textDisplayScale, this.c_render[i].y2 * multipler * textDisplayScale, findAngle(this.c_render[i].x1 * multipler * textDisplayScale, this.c_render[i].y1 * multipler * textDisplayScale, this.c_render[i].x2 * multipler * textDisplayScale, this.c_render[i].y2 * multipler * textDisplayScale), 14, 14);
                  // } else {
                  drawArrowhead(ctx, this.c_render[i].x2 * multipler * textDisplayScale, this.c_render[i].y2 * multipler * textDisplayScale, findAngle(this.c_render[i].x1 * multipler * textDisplayScale, this.c_render[i].y1 * multipler * textDisplayScale, this.c_render[i].x2 * multipler * textDisplayScale, this.c_render[i].y2 * multipler * textDisplayScale), 20 * multipler * textDisplayScale, 20 * multipler * textDisplayScale);
                  // }
                  break;
                case "fillTextMultiLine":
                  fillTextMultiLine(ctx, this.c_render[i].value, this.c_render[i].x*multipler * textDisplayScale, this.c_render[i].y*multipler * textDisplayScale);
                  break;
              }

            }
          }
          gauge_first_render_complete[this.elementId] = true;
        };
        configData.elementId = elementId;
        configData.responsive = true;
        configData.autoWidthToHeightRatio = 0.5;
        //configData.displayScaleOverride = 2; // Override dispalyscale 7/3/2019 - neil
        // 9/9/2019 - disabling the displayScaleOverride - that is causing crappy text scaling :(
        var target = document.getElementById(elementId);
        $(target).data("configData", configData);
        var myGauge = new Gauge(target).setOptions(configData);
        myGauge.minValue = 0;
        myGauge.maxValue = configData.c_max_val;
        myGauge.animationSpeed = 1;
        myGauge.set(configData.c_max_val);
        myGauge.animationSpeed = 120;


        if(animate_now) {
          myGauge.set(configData.c_max_val-position);
        } else {
          gauge_list.push({
            "elementId": elementId,
            "gauge": myGauge,
            "position": position
          });
        }
      });

    }
  }

  somefunction() {
    // access item in data-something="" with this.element.dataset.something OR this.data.get("something");
  }
  /*
  data-position="<%= percent_position %>" data-gauge_config="<%= Rails.configuration.wbi_cdn_url %>/gauges/<%= gauge.id %>.json" width="300" height="150" alt="<%= gauge.display_name %>
   */
}
