import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="institution-search"
export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;

    let $element = $(this.element);
    $element.css('width', '100%');
    let search_url = $element.data("url");

    let selectParent = $element.parent();
    let options = {
      tags: true,
      // Needed to fix IOS/fastclick issue - 6/23/2017 neil
      templateResult: function(result, container) {
        if (!result.id) {
          return result.content;
        }
        container.className += ' needsclick';
        return result.content;
      },
      templateSelection: function(result){
        return result.content || result.text;
      },
      minimumInputLength: 2,
      dropdownParent: selectParent,
      placeholder: i18n_string("users.account.enter_hospital_organization","Enter Hospital/Organization"),
      escapeMarkup: function (markup) {
        return markup;
      },
      ajax: {
        url: search_url,
        dataType: 'json',
        type: "POST",
        quietMillis: 100,
        data: function (term) {
          var thedata = {
            search: term,
            only_active: 1
          };
          if($("#use_state_in_search").is(":checked"))
          {
            if ($(".child-question-22").length && $(".child-question-22 select").length) {
              let $state_select = $(".child-question-22 select");
              if ($state_select.val()) {
                thedata.state_question_id = $state_select.data("question-id");
                thedata.state_option_id = $state_select.val();
              }
            }
          }
          // Mobile app search
          if ($("#appSplashSetupAccordion").length) {
            thedata = {
              search: term,
              wbi_version_id: $("#wbi_version_id").val()
            };
          }
          return thedata;
        }
      },
      theme: "bootstrap-5",
      containerCssClass: "select2--small",
      dropdownCssClass: "select2--small"
    };
    if ($element.data("allow_custom") == "yes") {
      options.createTag = function (params) {
        //console.log("TTTTTTTTTTag");
        return {
          id: "custom:" + params.term,
          text: params.term,
          newOption: true
        }
      };
    }
    $element.select2(options);
    let sel2Data = $element.data('select2');
    if(sel2Data && sel2Data.$container)
    {
      sel2Data.$container.find('*').addClass('needsclick');
    }
    $element.on('select2:open', function(e){
      const select2 = $(e.target).data('select2');
      if (select2 && select2.dropdown && select2.dropdown.$search && select2.dropdown.$search.get(0)) {
        select2.dropdown.$search.get(0).focus();
        let search_field = select2.dropdown.$search;
        let parent_container = search_field.parent();
        addLabelToSelect2SearchField(search_field, parent_container);
      }

    });
  }
}
