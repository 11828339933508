import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comparison-chart-modal"
export default class extends Controller {
  initialize() {
    let close_text = i18n_string("general.close", "Close");
    setupBootstrapModal("wbi-comparison-chart-modal", "", "", '<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">'+close_text+'</button>', 'body', '', 'modal-lg');

    window.drawWbiComparisonChart = function (){
      // Make sure connected chart_data is loaded
      if (!window.wbi_comparison_chart_data) {
        setTimeout(drawWbiComparisonChart, 500);
        return;
      }
      var data = new google.visualization.DataTable();
      data.addColumn('string', '');
      data.addColumn('number', i18n_string("general.dashboard.index_score","Index Score"));
      data.addColumn({type:'string', role:'annotation'});
      data.addColumn({type:'string', role:'style'});
      data.addRows(window.wbi_comparison_chart_data);

      var options = {
        annotations: {
          textStyle: {color: '#494949'},
          boxStyle: {
            stroke: '#dfdfdf',
            strokeWidth: 1,
            rx: 3,
            ry: 3,
            gradient: {
              color1: '#fefefe',
              color2: '#f2f2f2',
              x1: '0%', y1: '0%',
              x2: '100%', y2: '100%',
              useObjectBoundingBoxUnits: true
            }
          }
        },
        chartArea:{
          //left: 'auto',
          // width: 'auto',
          top: 20,
          right: 20,
          left: 150,
          height: '80%'
        },
        backgroundColor: 'transparent',
        pointSize: 14,
        colors:['#2258b1'],

        legend: 'none',
        vAxis: {
          minValue: 0,
          maxValue: 7,
          direction: -1,
          ticks: [{v:1, f:i18n_string("general.dashboard.wbi_over_time_excellent","Excellent")},
            {v:2, f:i18n_string("general.dashboard.wbi_over_time_above_avg","Above Average")},
            {v:3, f:i18n_string("general.dashboard.wbi_over_time_avg","Average")},
            {v:4, f:i18n_string("general.dashboard.wbi_over_time_below_avg","Below Average")},
            {v:5, f:i18n_string("general.dashboard.wbi_over_time_low","Low")},
            {v:6, f:i18n_string("general.dashboard.wbi_over_time_very_low","Very Low")},
            {v:7, f:i18n_string("general.dashboard.wbi_over_time_extra_low","Extremely Low")}],
          baseline: {
            color: '#d8d8d8'
          },
          gridlines: {
            count: 7,
            color: '#CCCCCC'
          },
          textStyle: {
            color: '#000000',
            fontSize: '13'
          },
          titleTextStyle: {
            color: '#000000',
            fontSize: '15',
            bold: true
          },
          title: i18n_string("general.dashboard.index_score","Index Score")
        },
        hAxis: {
          textStyle: {
            color: '#000000',
            fontSize: '11'
          },
          titleTextStyle: {
            color: '#000000',
            fontSize: '15',
            bold: true
          },
          title: ''
        },
        tooltip: {
          trigger: 'none',
          isHtml: true,
          textStyle: {
            color: '#7E7F74',
            fontSize: '14'
          }
        }
      }

      // Instantiate and draw our chart, passing in some options.
      var chart = new google.visualization.ScatterChart(document.getElementById('wbi-comparison-chart-div'));

      chart.draw(data, options);
    };

    load_google_charts();
    add_google_chart_onload_callback(drawWbiComparisonChart);

    $(document).on('shown.bs.modal', '#wbi-comparison-chart-modal', function(){
      drawWbiComparisonChart(); // Redraw
    });
  }
  connect() {
    this.element[this.identifier] = this;

    // Return if modal/data is already setup
    if (window.wbi_comparison_chart_data) {
      return;
    }
    if (!this.element.dataset || !this.element.dataset.chartData || !this.element.dataset.group) {
      //console.log("I'm missing something");
      return;
    }
    let chart_data = JSON.parse(this.element.dataset.chartData);
    for (let i=0;i<chart_data.length;i++) {
      chart_data[i][1] = Number.parseFloat(chart_data[i][1]);
    }
    let group = this.element.dataset.group.toLowerCase();
    let org_compare = this.element.dataset.orgCompare;
    let org_name = this.element.dataset.orgName;

    // Title
    $("#wbi-comparison-chart-modal .modal-title").html(i18n_string("survey.comparison.my_score_vs_average_for", "My Well-Being Index Score vs. Average for")+" "+group);
    // Modal Body Setup
    let body_html = '<h2 style="margin-bottom: -52px;">'+i18n_string("survey.comparison.compare_your_score_vs_average_for", "Compare your Well-Being Index score against the average for")+' '+group+'.</h2>';
    body_html += '<div id="wbi-comparison-chart-div" class="responsive-chart"></div>';
    body_html += '<div class="text-center">';
    for (let i=0;i<chart_data.length;i++){
      body_html += '<span class="text-nowrap me-3" ><span class="report-client-dot " ';
      body_html += ' style="background-color:'+chart_data[i][3]+';float:none;display:inline-block;margin:9px 3px 0px 0px;"></span>';
      body_html += chart_data[i][2];
      body_html += '</span>';
    }
    body_html += '</div>';
    if (org_compare && org_compare > 0 && org_name) {
      body_html += '<div class="clearfix"></div>';
      body_html += '<div class="mt-3" style="font-size:90%; color: #898989;">';
      body_html += '  ' + i18n_string("survey.comparison.this_report_is_generate_when_at_least", "This report is generated when at least") + ' ' + org_compare + ' ';
      body_html += '  ' + group + ' ' + i18n_string("survey.comparison.from_your_organization_have_assessed", "from your organization have assessed.");
      body_html += '</div>';
    }
    $("#wbi-comparison-chart-modal .modal-body").html(body_html);


    window.wbi_comparison_chart_data = chart_data;
  }
}
