window.window_width = $(window).width();
window.window_width_min = 992;
window.mobile_walkthrough = false;

if (window_width < window_width_min) {
    mobile_walkthrough = true;
}

window.helper_menu_item_height = '62px';
if (window_width >= 1200) {
    helper_menu_item_height = '72px';
}
window.helper_menu_item_text_sizes = 'font-size: 14px;line-height: 21px;margin-left: 5px;';
if (window_width >= 1200) {
    helper_menu_item_text_sizes = 'font-size: 18px;line-height: 21px;margin-left: 10px;';
}

window.intro = undefined;
window.introjs_page = undefined;
window.intro_loading_next_page = false;
window.redirectURL = undefined;
window.introJsStarted = false;

window.initIntroJs = function() {
    if (intro) {
        intro.exit(true);
    }
    window.nextText = i18n_string("general.next", "Next");
    window.backText = i18n_string("general.back", "Back");
    intro = introJs();
    redirectURL = undefined;
    var doneLabel = i18n_string("general.finish", 'Finish');
    var stepArray = [];
    var shouldStartOnLoad = getCookie("introjs_startonload");
    var introjs_page = getCookie("introjs_page");
    if (introjs_page == "/starter_kit" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.starterkit",
                position: 'right',
                //helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-index" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Starter Kit</span></a></div>',
                intro: i18n_string('walkthrough.on_the_starter_kit_page_you_will_have_access_to_va', "On the <strong>Admin Resources</strong> page you will have access to valuable resources for setting up your Well-Being Index account and having a successful launch.")
            }
        );
        doneLabel = window.nextText+' →';
        doneLabel = window.nextText+' →';
        redirectURL = "/admin_toolkit";
    } else if (introjs_page == "/admin_toolkit" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.admintoolkit",
                position: 'right',
                //helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-index" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Starter Kit</span></a></div>',
                intro: i18n_string('walkthrough.on_the_starter_kit_page_you_will_have_access_to_va', "On the <strong>Admin Resources</strong> page you will have access to valulable resources for setting up your Well-Being Index account and having a successful launch.")
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/administrator";
    } else if (introjs_page == "/administrator" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.manage-admins",
                position: 'right',
                //helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-admins" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Manage Admins</span></a></div>',
                intro: i18n_string('walkthrough.on_the_manage_admins_page_you_can_add_additional_a', "On the <strong>Account Management > Administrators</strong> page you can add additional administrators to your account.")
                //,action: "collapse_nav"
            }
        );
        stepArray.push(
            {
                element: ".sub-nav.sub-nav-header-full",
                intro: i18n_string('walkthrough.adding_new_administrators_can_be_done_through_a_bu', "Adding new administrators can be done through a bulk import or adding individually. When adding a new administrator you can set their permissions to be 'View Reports' if you do not want them to have full edit access."),
                position: 'bottom',
                //action: "new_admin_radio"
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/participants";

    } else if (introjs_page == "/participants" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.invite-users",
                position: 'right',
                //helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-users" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Invite Participants</span></a></div>',
                intro: i18n_string('walkthrough.on_the_invite_participants_page_you_can_access_you', "On the <strong>Assessment Campaigns > Invite Participants</strong> page you can access your unique invitation code, get the invitation template, and send test invitations.")
            }
        );
        stepArray.push(
            {
                element: "#tour-invite-code-left",
                intro: i18n_string('walkthrough.here_you_will_find_the_signup_url_and_your_unique_', "Here you will find the signup URL and your unique invitation code."),
                position: (mobile_walkthrough ? 'top' : 'right')
            }
        );
        stepArray.push(
            {
                element: "#tour-invite-code-right",
                intro: i18n_string('walkthrough.this_is_an_example_of_an_invitation_email__you_can', "This is an example of an invitation email.  You can copy it from here and use it in an external email system."),
                position: (mobile_walkthrough ? 'top' : 'left'),
                action: 'main_inv_tab'
            }
        );
        // Check to make sure internal invitations are enabled --
        if ($("#email_invitations").length) {
            stepArray.push(
                {
                    element: "#tour-invite-email-left",
                    intro: i18n_string('walkthrough.send_an_email_invitation_to_a_single_email_address', "Send an email invitation to a single email address."),
                    position: (mobile_walkthrough ? 'top' : 'right'),
                    action: 'email_inv_tab'
                }
            );
            stepArray.push(
                {
                    element: "#tour-invite-email-right",
                    intro: i18n_string('walkthrough.upload_a_spreadsheet_of_email_addresses_to_send_in', "Upload a spreadsheet of email addresses to send invitations."),
                    position: (mobile_walkthrough ? 'top' : 'left'),
                    action: 'email_inv_tab'
                }
            );
        }
        // Send Test Invites
        if ($("#send_test_invites").length) {
            stepArray.push(
                {
                    element: "#send-test-invites-wrapper",
                    intro: i18n_string('walkthrough.send_out_test_invitations_to_your_organization_sta', "Send out test invitations to your organization staff.  Test accounts will be automatically removed after 5 days."),
                    position: 'top',
                    action: 'email_test_inv_tab'
                }
            );
            // stepArray.push(
            //     {
            //         element: "#current-test-accounts-table-wrapper",
            //         intro: "List of sent test invites and test accounts that will be removed after 5 days.",
            //         //action: "example_test_invites",
            //         position: 'top'
            //     }
            // );
        }


        doneLabel = window.nextText+' →';
        redirectURL = "/notifications";
    } else if (introjs_page == "/notifications" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.reminders",
                position: 'right',
                //helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-reminders" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Reminders/Notifications</span></a></div>',
                intro: i18n_string('walkthrough.on_the_remindersnotifications_page_you_can_configu', "On the <strong>Account Management > Participant Notification Settings</strong> page, you can configure automated email settings and view samples emails that are sent."),
            }
        );
        stepArray.push(
            {
                element: "#customize-auto-email-settings-div",
                position: 'top',
                intro: i18n_string('walkthrough.enabledisable_reassessment_comparison_and_account_', "Enable/disable reassessment, comparison, and account verification emails.  Set reassessment reminder email frequency."),
            }
        );
        if ($("#reassess_enabled_div").is(":visible")) {
            stepArray.push(
                {
                    element: "#reassess_enabled_div",
                    position: 'top',
                    intro: i18n_string('walkthrough.configure_reassessment_email_frequency_or_setup_to', "Configure reassessment email frequency or setup to send on specific dates."),
                }
            );
        }


        doneLabel = window.nextText+' →';
        redirectURL = "/resources/manage";
    } else if (introjs_page == "/resources/manage" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.resources",
                position: 'right',
                // helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-resources" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Manage Resources</span></a></div>',
                intro: i18n_string('walkthrough.on_the_manage_resources_page_you_can_edit_resource', "On the <strong>Account Management > Organizational Resources</strong> page you can edit resources specific to your organization."),
            }
        );
        stepArray.push(
            {
                element: "#tour-res-email-signature",
                intro: i18n_string('walkthrough.this_resource_is_the_email_signature_you_want_to_a', "This resource is the email signature you want to appear at the bottom of all automated emails."),
                position: 'top',
                scrollPadding: 100
            }
        );
        stepArray.push(
            {
                element: "#tour-res-header-logo",
                intro: i18n_string('walkthrough.this_resource_is_for_customizing_the_logo_you_want', "This resource is for customizing the logo you want participants to see when signing up and using the software.  The logo will be displayed in the upper right of the software."),
                position: 'top'
            }
        );
        stepArray.push(
            {
                element: "#tour-res-high-risk-info",
                intro: i18n_string('walkthrough.this_resource_is_for_customizing_the_information_d', "This resource is for customizing the information displayed after taking the assessment to participants that are high risk."),
                position: 'top'
            }
        );
        stepArray.push(
            {
                element: "#tour-res-preview-btn",
                intro: i18n_string('walkthrough.click_this_button_to_preview_the_resources_as_a_us', "Click this button to preview the resources as a user at your organization.  You will be able to view all of your local resources as well as the national resources available."),
                position: 'bottom'
            }
        );
        stepArray.push(
            {
                element: "#tour-add-res-btn",
                intro: i18n_string('walkthrough.when_you_want_to_add_a_new_local_resource_click_he', "When you want to add a new local resource, click here."),
                position: 'top'
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/resources/new";
    } else if (introjs_page == "/resources/new" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: "#t-resource-checkboxes",
                intro: i18n_string('walkthrough.when_editing_a_custom_resource_you_must_first_sele', "<div style=\"width:380px;\">When editing a custom resource, you must first select the categories that you want it to be displayed in.  You must select at least 1 category.</div>"),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#t-title-phone-intranet",
                intro: i18n_string('walkthrough.optionally_set_a_titlelocation_and_phone_number_if', "Optionally set a title/location and phone number. If the resource in only accessible when connected to your network then select 'Yes' under 'Intranet Resource'."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#t-res-additional-content",
                intro: i18n_string('walkthrough.add_additional_content_here__you_can_add_links_tab', "Add additional content here.  You can add links, tables, and images via this text area."),
                position: "top"
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/resources/manage?tour=2";
    } else if (introjs_page == "/resources/manage?tour=2" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                //element: "#edit-order-dropdown-select",
                element: "#edit-order-dropdown-select-wrap",
                intro: i18n_string('walkthrough.once_you_have_your_resources_added_you_can_change_', "Once you have your resources added, you can change the order that they are displayed.  Select the category from this dropdown that you want to configure the order to."),
                position: "top"
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/resources/" + document.tour_example_res_id + "/sort?tour=3";
    } else if (introjs_page == "/resources/" + document.tour_example_res_id + "/sort?tour=3" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: "#sortable-resources",
                intro: i18n_string('walkthrough.drag_and_drop_the_resources_to_reorder_them_the_or', "Drag and drop the resources to reorder them. The order will save automatically after dropping them."),
                position: "top",
                action: "example_resources_for_order"
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/signup-questions";
    } else if (introjs_page == "/signup-questions" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.signup-questions",
                position: 'right',
                //helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-questions" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Signup Questions</span></a></div>',
                intro: i18n_string('walkthrough.on_the_signup_questions_page_you_will_be_able_to_c', "On the <strong>Account Management > Participant Account Questions</strong> page, you will be able to customize the signup questions.")
            }
        );
        if ($("#tour-signup-quest-hide").length) {
            stepArray.push(
                {
                    element: "#tour-signup-quest-hide",
                    intro: i18n_string('walkthrough.you_can_hide_specific_options_from_these_signup_qu', "You can hide specific options from these signup questions if they do not apply to your organization."),
                    position: "top"
                }
            );
        }
        stepArray.push(
            {
                element: "#tour-signup-quest-custom",
                intro: i18n_string('walkthrough.you_can_add_custom_signup_questions_here__when_add', "You can add custom signup questions here.  When adding a custom question, it will create a dropdown with options for users when they signup.  You can drag/drop to reorder the questions.  From the reports section, you can view the mean scores based on the selected answers."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#t-preview-account-signup",
                intro: i18n_string('walkthrough.you_can_preview_your_account_signup_page_here_', "You can preview your account signup page here."),
                position: "bottom"
            }
        );
        stepArray.push(
            {
                element: "#t-view-demo",
                intro: i18n_string('walkthrough.you_can_view_the_demo_here_this_will_take_you_to_t', "You can view the demo here. This will take you to the Well-Being Index demo site."),
                position: "bottom"
            }
        );

        doneLabel = window.nextText+' →';
        redirectURL = "/signup-questions/add";
    } else if (introjs_page == "/signup-questions/add" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: "#tour-signup-quest-custom-text",
                intro: i18n_string('walkthrough.when_adding_or_editing_a_custom_question_you_first', "When adding or editing a custom question, you first create your question here."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#tour-signup-quest-custom-report",
                intro: i18n_string('walkthrough.enter_the_associated_name_of_the_report_', "Enter the associated name of the report."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#tour-signup-quest-custom-multiple",
                intro: i18n_string('walkthrough.check_this_if_you_want_to_allow_users_to_select_mo', "Check this if you want to allow users to select more than one option for this question."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#tour-signup-quest-custom-options",
                intro: i18n_string('walkthrough.click_on_add_option_to_add_the_option_you_will_nee', "Click on 'Add Option' to add the option you will need.  Then fill in the text and drag/drop the rows to change the order that you want the options displayed."),
                position: "top",
                //action: "sample_signup_questions"
            }
        );

        doneLabel = window.nextText+' →';
        // if (document.tour_parts.indexOf('reports') != -1 || document.tour_parts.indexOf('admin') != -1) {
        //     redirectURL = "/reports/0/show_report?report=percent_high_levels_of_distress";
        // } else {
        redirectURL = "/dashboard?tour=done";
        // }
    } else if (introjs_page == "/reports/0/show_report?report=percent_high_levels_of_distress" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.reports",
                position: 'right',
                // helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-reports" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Reports</span></a></div>',
                intro: i18n_string('walkthrough.on_any_of_the_reports_pages_the_interface_will_loo', "On any of the <strong>Reports</strong> pages, the interface will look similar to this report.")
            }
        );
        stepArray.push(
            {
                element: "#choose-report-btn-group",
                intro: i18n_string('walkthrough.in_the_upper_right_you_can_quickly_switch_to_a_dif', "In the upper right you can quickly switch to a different report using this dropdown."),
                position: "bottom"
            }
        );
        stepArray.push(
            {
                element: "#main-chart-wrapper",
                intro: i18n_string('walkthrough.in_the_main_area_will_be_the_chart_', "In the main area will be the chart."),
                position: (mobile_walkthrough ? 'top' : "right")
            }
        );
        stepArray.push(
            {
                element: "#table-data-holder",
                intro: i18n_string('walkthrough.below_the_chart_will_be_a_table_of_the_data_from_t', "Below the chart will be a table of the data from the chart."),
                position: (mobile_walkthrough ? 'top' : "right")
            }
        );
        // var report_button_wrapper = ".hidden-sm-down";
        // if (window_width < 768) {
        //     report_button_wrapper = ".hidden-md-up";
        // }
        stepArray.push(
            {
                // element: report_button_wrapper + " .report-settings-panel-open-btn",
                element: ".configure-report-button",
                intro: i18n_string('walkthrough.here_you_can_open_the_configuration_settings_for_t', "Here you can open the configuration settings for the report."),
                position: (mobile_walkthrough ? 'top' : "left")
            }
        );
        //  --  report-settings-panel-open-btn
        stepArray.push(
            {
                element: "#configureReportOptions",
                intro: i18n_string('walkthrough.in_the_reports_settings_you_can_choose_style_perio', "In the reports settings you can choose style, period, type, and other options based on the type of report."),
                position: 'left',
                action: 'open_report_settings_panel'
            }
        );
        stepArray.push(
            {
                // element: report_button_wrapper + " .download-chart-button-group",
                element: "#download-report-btn-group",
                intro: i18n_string('walkthrough.here_you_can_download_a_pdf_jpeg_or_excel_version_', "Here you can download a PDF, JPEG, or Excel version of this report."),
                position: (mobile_walkthrough ? 'top' : "left"),
                action: 'close_report_settings_panel'
            }
        );
        if ($("#advanced-reports-btn").length) {
            stepArray.push(
                {
                    element: "#advanced-reports-btn",
                    intro: i18n_string('walkthrough.here_you_can_access_and_create_advanced_reports_', "Here you can access and create advanced reports."),
                    position: (mobile_walkthrough ? 'top' : "left")
                }
            );
            doneLabel = window.nextText+' →';
            redirectURL = "/advanced-report";
        } else if ($(".process-improve").length) {
            doneLabel = window.nextText+' →';
            redirectURL = "/process-improvement";
        } else {
            doneLabel = window.nextText+' →';
            redirectURL = "/dashboard?tour=done";
        }
    } else if (introjs_page == "/advanced-report" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                //element: ".table.table-striped",
                intro: i18n_string('walkthrough.all_saved_advanced_reports_will_be_listed_here__yo', "All saved advanced reports will be listed here.  You can edit, remove, and run the report from this list. Reports can be organized into folders."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: ".new-adv-report-btn",
                intro: i18n_string('walkthrough.here_you_can_create_a_new_advanced_report_', "Here you can create a new advanced report."),
                position: (mobile_walkthrough ? 'top' : "right")
            }
        );
        doneLabel = window.nextText+' →';
        redirectURL = "/advanced-report/new";
    } else if (introjs_page == "/advanced-report/new" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                intro: i18n_string('walkthrough.the_advanced_report_editor_allows_for_combining_sp', "The advanced report editor allows for combining specific groups based on demographic and custom question answers."),
            }
        );
        stepArray.push(
            {
                element: "#t-adv-report-name",
                intro: i18n_string('walkthrough.the_first_step_is_input_the_name_of_the_report_', "The first step is input the name of the report."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#t-adv-filter-options",
                intro: i18n_string('walkthrough.the_second_step_is_select_the_options_from_each_qu', "The second step is select the options from each question that you want in your report.  You can group options together into a custom group as well."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#selected_question_options_list",
                intro: i18n_string('walkthrough.the_third_step_is_to_select_one_or_more_optionsgro', "The third step is to select one or more options/groups to combine and add them to your report."),
                position: "top"
            }
        );

        stepArray.push(
            {
                element: "#group_combo_table",
                intro: i18n_string('walkthrough.once_you_add_the_combined_optionsgroups_they_will_', "Once you add the combined options/groups they will show here with the sample size and mean score for that group.  This can be used to check if there is enough participants in a particular group.  Once you add all of your combinations, then you can save and run the report."),
                position: "top"
            }
        );
        if ($(".process-improve").length) {
            doneLabel = window.nextText+' →';
            redirectURL = "/process-improvement";
        } else {
            doneLabel = window.nextText+' →';
            redirectURL = "/dashboard?tour=done";
        }
    } else if (introjs_page == "/process-improvement" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                element: ".nav-link.process-improve",
                position: 'right',
                // helperHTML: '<div style="padding: 5px 6px 6px 4px;"><a href="" style="background-color: rgb(18, 56, 116);border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255) rgb(116, 142, 183);border-left-style: solid;border-left-width: 5px;box-sizing: border-box;color: rgb(255, 255, 255);display: block;font-size: 18px;height: ' + helper_menu_item_height + ';line-height: 27px;list-style-type: none;outline: rgb(59, 153, 252) auto 0px;overflow: hidden;padding: 10px 20px 10px 12px;text-align: left;text-decoration: none solid rgb(255, 255, 255);text-size-adjust: 100%;vertical-align: middle;white-space: nowrap;"><i class="icon-research" style="box-sizing: border-box;font-family: wbi;font-size: 42px;font-style: normal;line-height: 42px;speak: none;vertical-align: middle;-webkit-font-smoothing: antialiased;"></i> <span style="box-sizing: border-box;' + helper_menu_item_height + '">Process Improvement</span></a></div>',
                intro: i18n_string('walkthrough.process_improvement_questions_can_be_used_to_get_f', "Process improvement questions can be used to get feedback or suggestions from your participates.")
            }
        );
        stepArray.push(
            {
                element: "#t-process-improvement-question-table",
                intro: i18n_string('walkthrough.here_will_be_listed_all_process_improvement_questi', "Here will be listed all process improvement questions.  You can have 3 active questions at a time.  Clicking on the status label will activate/deactivate the question."),
                position: "top",
                action: 'example_process_improvement_questions'
            }
        );
        stepArray.push(
            {
                element: "#t-add-improvement-question",
                intro: i18n_string('walkthrough.here_you_can_create_new_process_improvement_questi', "Here you can create new process improvement questions."),
                position: "top"
            }
        );
        stepArray.push(
            {
                element: "#improvement-sample-email-template",
                intro: i18n_string('walkthrough.at_the_bottom_you_can_view_and_download_the_proces', "At the bottom you can view and download the process improvement email template to send from your own email system."),
                position: "top"
            }
        );

        doneLabel = window.nextText+' →';
        redirectURL = "/dashboard?tour=done";
    } else if (introjs_page == "/dashboard?tour=done" && shouldStartOnLoad == "yes") {
        stepArray.push(
            {
                intro: i18n_string('walkthrough.tour_completedyou_can_view_this_tour_anytime_by_go', "<h2>Tour Completed</h2>You can view this tour anytime by going to the Help page."),
            }
        );
    } else {
        stepArray.push(
            {
                intro: i18n_string('walkthrough.wellbeing_index_tourwe_will_give_you_a_quick_tour_', "<div style=\"width:300px;\"><h2>Well-Being Index Tour</h2>We will give you a quick tour and show you the different areas of the site. You can navigate the tour using the <strong>Prev/Next</strong> buttons or the <strong>left</strong> and <strong>right</strong> arrows on your keyboard.</div>"),
            }
        );
        stepArray.push(
            {
                element: "#navigation-col",
                intro: i18n_string('walkthrough.the_main_navigation_can_be_collapsed_and_expanded_', "The main navigation can be collapsed and expanded using the hamburger icon to the right of it.  This menu will have all of the links you will need to navigate the tool."),
                position: 'right',
                action: 'expand_nav'
            }
        );
        var nav_active = "";
        if (window_width < 1024) {
            nav_active = "active";
        }
        stepArray.push(
            {
                element: "nav.utility-nav",
                intro: i18n_string('walkthrough.at_the_bottom_of_the_navigation_you_will_see_links', "<div style=\"width:340px;\">At the bottom of the navigation you will see links to go to your account page (to update your password), help documentation, and the logout button.</div>"),
                position: 'right',
                action: 'expand_nav',
                helperHTML: '<div class="' + nav_active + '"><div id="sidebar" style="position:relative;border:6px solid #FFF;border-radius:4px;"><nav class="utility-nav introjs-showElement introjs-relativePosition"><a href="#"><i class="fa fa-gear"></i><span>My Account</span></a><a href="#"><i class="fa fa-question"></i><span>Help</span></a><a href="#"><i class="fa fa-sign-out"></i><span>Logout</span></a></nav></div></div>'
            }
        );
        doneLabel = window.nextText+' →';

        if (document.tour_parts.indexOf('admin') != -1) {
            if ($("a.starter-kit").length) {
                redirectURL = "/starter_kit";
            } 
            else if($("a.admin-toolkit").length) {
                redirectURL = "/admin_toolkit";
            } 
            else {
                redirectURL = "/administrator";
            }
        } else if (document.tour_parts.indexOf('reports') != -1 || document.tour_parts.indexOf('admin') != -1) {
            redirectURL = "/reports?report=at_risk";
        } else {
            redirectURL = "/dashboard?tour=done";
        }

        // console.log("redirect_url:", redirectURL)

    }

    intro.setOptions({
        steps: stepArray,
        showBullets: false,
        scrollToElement: true,
        scrollTo: 'tooltip',
        showStepNumbers: false,
        keyboardNavigation: false,
        //hideNext: true,
        nextToDone: true,
        skipLabel: i18n_string("general.exit", "Exit"),
        doneLabel: doneLabel,
        nextLabel: window.nextText+" →",
        prevLabel: "← "+window.backText,
    });
    if (redirectURL) {
        intro.oncomplete(function () {
            intro_loading_next_page = true;
            setCookie("introjs_page", redirectURL, 1);
            setCookie("introjs_startonload", "yes", 1);
            window.location.href = redirectURL;
        });
    }

    intro.onbeforechange(function (targetElement) {
        document.introjsHelperHTML = '';
        $(".introjs-helperLayer").html("");
        if (this._introItems && this._currentStep >= 0 && this._introItems[this._currentStep]) {
            var stepObj = this._introItems[this._currentStep];
            if (stepObj.helperHTML) {
                // introjs-helperLayer  introjs-fixedTooltip
                if ($(".introjs-helperLayer").length) {
                    $(".introjs-helperLayer").html(stepObj.helperHTML);
                } else {
                    document.introjsHelperHTML = stepObj.helperHTML;
                    setTimeout(updateHelperHTML, 50);
                }
            } else if (stepObj.action) {
                if (stepObj.action == "expand_nav") {
                    $("body").removeClass("nav-toggled");
                } else if (stepObj.action == "collapse_nav") {
                    $("body").addClass("nav-toggled");
                } else if (stepObj.action == "main_inv_tab") {
                    $("button[data-bs-target='#invitation_code']").closest(".nav").find(".nav-link").removeClass("active");
                    $("button[data-bs-target='#invitation_code']").addClass("active");
                    $(".tab-content").find(".tab-pane").removeClass("active").removeClass("show");
                    $("#invitation_code").addClass("active").addClass("show");
                } else if (stepObj.action == "email_inv_tab") {
                    $("button[data-bs-target='#email_invitations']").closest(".nav").find(".nav-link").removeClass("active");
                    $("button[data-bs-target='#email_invitations']").addClass("active");
                    $(".tab-content").find(".tab-pane").removeClass("active").removeClass("show");
                    $("#email_invitations").addClass("active").addClass("show");
                } else if (stepObj.action == "email_test_inv_tab") {
                    $(window).scrollTop(0);
                    $("button[data-bs-target='#send_test_invites']").closest(".nav").find(".nav-link").removeClass("active");
                    $("button[data-bs-target='#send_test_invites']").addClass("active");
                    $(".tab-content").find(".tab-pane").removeClass("active").removeClass("show");
                    $("#send_test_invites").addClass("active").addClass("show");
                } else if (stepObj.action == "example_test_invites") {
                    var example_invites = '';
                    example_invites += '<tr>';
                    example_invites += '  <td>test.user1@yourorganization.com</td>';
                    example_invites += '  <td>';
                    example_invites += '    <span class="badge bg-primary">Signed Up</span>';
                    example_invites += '  </td>';
                    example_invites += '  <td>09/03/2018</td>';
                    example_invites += '  <td>';
                    example_invites += '    <a href="#"><em class="fa fa-times text-danger"></em><span class="sr-only">Remove Now</span></a>';
                    example_invites += '    <a href="#"><em class="fa fa-refresh text-success"></em><span class="sr-only">Reset Auto-removal Time</span></a>';
                    example_invites += '  </td>';
                    example_invites += '</tr>';
                    example_invites += '<tr>';
                    example_invites += '  <td>test.user2@yourorganization.com</td>';
                    example_invites += '  <td>';
                    example_invites += '    <span class="badge badge-success">Sent</span>';
                    example_invites += '  </td>';
                    example_invites += '  <td>09/03/2018</td>';
                    example_invites += '  <td>';
                    example_invites += '    <a href="#"><em class="fa fa-times text-danger"></em><span class="sr-only">Remove Now</span></a>';
                    example_invites += '    <a href="#"><em class="fa fa-envelope-o text-primary"></em><span class="sr-only">Resend Invite Email</span></a>';
                    example_invites += '  </td>';
                    example_invites += '</tr>';
                    example_invites += '<tr>';
                    example_invites += '  <td>test.user3@yourorganization.com</td>';
                    example_invites += '  <td>';
                    example_invites += '    <span class="badge badge-success">Sent</span>';
                    example_invites += '  </td>';
                    example_invites += '  <td>09/03/2018</td>';
                    example_invites += '  <td>';
                    example_invites += '    <a href="#"><em class="fa fa-times text-danger"></em><span class="sr-only">Remove Now</span></a>';
                    example_invites += '    <a href="#"><em class="fa fa-envelope-o text-primary"></em><span class="sr-only">Resend Invite Email</span></a>';
                    example_invites += '  </td>';
                    example_invites += '</tr>';

                    $("#test_inv table tbody").html(example_invites);
                } else if (stepObj.action == "example_resources_for_order") {
                    var example_res = '<li class="sortable-resources-ui-state-highlight list-group-item ui-sortable-handle"><em class="fa fa-arrows"></em> Phone: 555-555-5555</li>';
                    example_res += '<li class="sortable-resources-ui-state-highlight list-group-item ui-sortable-handle"><em class="fa fa-arrows"></em> Phone: 111-444-2222</li>';
                    example_res += '<li class="sortable-resources-ui-state-highlight list-group-item ui-sortable-handle"><em class="fa fa-arrows"></em> http://www.yourinternallink.com</li>';
                    example_res += '<li class="sortable-resources-ui-state-highlight list-group-item ui-sortable-handle"><em class="fa fa-arrows"></em> http://www.yourorganization.com</li>';
                    example_res += '<li class="sortable-resources-ui-state-highlight list-group-item ui-sortable-handle"><em class="fa fa-arrows"></em> http://www.helplink.com</li>';

                    $("#sortable-resources").html(example_res);
                } else if (stepObj.action == "sample_signup_questions") {
                    var example_html = '<tr class="ui-sortable-handle"><td align="center" valign="center" style="vertical-align: inherit;"><em class="fa fa-arrows" style="font-size:20px;color:#cccccc;"></em></td><td><input type="text" class="form-control" value="Example Option 1"></td><td style="display:none;"></td><td align="center" class="text-center"><input type="checkbox" ></td></tr>';
                    example_html += '<tr class="ui-sortable-handle"><td align="center" valign="center" style="vertical-align: inherit;"><em class="fa fa-arrows" style="font-size:20px;color:#cccccc;"></em></td><td><input type="text" class="form-control" value="Example Option 2"></td><td style="display:none;"></td><td align="center" class="text-center"><input type="checkbox" ></td></tr>';
                    example_html += '<tr class="ui-sortable-handle"><td align="center" valign="center" style="vertical-align: inherit;"><em class="fa fa-arrows" style="font-size:20px;color:#cccccc;"></em></td><td><input type="text" class="form-control" value="Example Option 3"></td><td style="display:none;"></td><td align="center" class="text-center"><input type="checkbox" ></td></tr>';
                    example_html += '<tr class="ui-sortable-handle"><td align="center" valign="center" style="vertical-align: inherit;"><em class="fa fa-arrows" style="font-size:20px;color:#cccccc;"></em></td><td><input type="text" class="form-control" value="Example Option 4"></td><td style="display:none;"></td><td align="center" class="text-center"><input type="checkbox" ></td></tr>';
                    example_html += '<tr class="ui-sortable-handle"><td align="center" valign="center" style="vertical-align: inherit;"><em class="fa fa-arrows" style="font-size:20px;color:#cccccc;"></em></td><td><input type="text" class="form-control" value="Example Option 5"></td><td style="display:none;"></td><td align="center" class="text-center"><input type="checkbox" ></td></tr>';

                    $("#option_table_body").html(example_html);
                } else if (stepObj.action == "new_admin_radio") {
                    $("#new-admin-radio-new").prop("checked", true).click();
                } else if (stepObj.action == "open_report_settings_panel") {
                    $("#configureReportOptions").addClass("notransition");
                    $(".configure-report-button").click();
                    //$("#configureReportOptions").addClass("show").css('visibility','visible');

                    setTimeout(function () {
                        $("#configureReportOptions").removeClass("notransition");
                    }, 500);
                } else if (stepObj.action == "close_report_settings_panel") {
                    $("#configureReportOptions").addClass("notransition");
                    $("#configureReportOptions .btn-close").click();

                    setTimeout(function () {
                        $("#configureReportOptions").removeClass("notransition");
                    }, 500);
                } else if (stepObj.action == "example_process_improvement_questions") {
                    if ($("#t-process-imrpovement-question-table tbody tr").length <= 1) {
                        if ($("#t-process-imrpovement-question-table .no-process-questions").length) {
                            var example_html = '<tr><td>Your process improvement question 1</td><td nowrap="nowrap"><a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Toggle Status" class="text-success"><em class="fa fa-star"></em> Active</a></td><td class="text-right"><a href="process-improvement?edit=example" class="btn"><em data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" class="fa fa-pencil-square-o text-success"></em><span class="sr-only">Edit</span></a> <a href="#" class="btn disabled"><em data-bs-toggle="tooltip" data-bs-placement="top" title=View Report" class="fa fa-bar-chart text-muted"></em><span class="sr-only">View Report</span></a> <a href="#" class="btn disabled"><em data-bs-toggle="tooltip" data-bs-placement="top" title=Delete" class="fa fa-times text-muted"></em><span class="sr-only">Delete</span></a></td></tr>';
                            example_html += '<tr><td>Your process improvement question 2</td><td nowrap="nowrap"><a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title=Toggle Status" class="text-success"><em class="fa fa-star"></em> Active</a></td><td class="text-right"><a href="process-improvement?edit=example" class="btn"><em data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" class="fa fa-pencil-square-o text-success"></em><span class="sr-only">Edit</span></a> <a href="#" class="btn disabled"><em data-bs-toggle="tooltip" data-bs-placement="top" title="View Report" class="fa fa-bar-chart text-muted"></em><span class="sr-only">View Report</span></a> <a href="#" class="btn disabled"><em data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" class="fa fa-times text-muted"></em><span class="sr-only">Delete</span></a></td></tr>';

                            $("#t-process-imrpovement-question-table tbody").html(example_html);
                        }
                    }
                }

            }
        }
    });
    intro.onexit(function () {
        if (!intro_loading_next_page) {
            setCookie("introjs_page", "", -30);
            setCookie("introjs_startonload", "", -30);
        }
    });
    intro.addHints();
}

window.setupWalkthrough = function() {
    if (typeof introJs === 'undefined') {
        return;
    }
    initIntroJs();
    var shouldStartOnLoad = getCookie("introjs_startonload");
    if (shouldStartOnLoad == "yes") {
        setCookie("introjs_startonload", "", -30);
        intro.start();
        introJsStarted = true;
    }

    $(document).off("click", ".start-tour").on("click", ".start-tour", function (e) {
        e.preventDefault();
        redirect_to_start_tour();
    });

    $(document).keydown(function (e) {
        if (intro && introJsStarted) {
            switch (e.which) {
                case 37: // left
                    if ($(".introjs-prevbutton").is(":visible")) {
                        intro.previousStep();
                        e.preventDefault();
                    }
                    break;
                case 39: // right
                    if ($(".introjs-nextbutton").is(":visible")) {
                        intro.nextStep();
                        e.preventDefault();
                    } else if ($(".introjs-donebutton").is(":visible")) {
                        if (redirectURL) {
                            intro_loading_next_page = true;
                            setCookie("introjs_page", redirectURL, 1);
                            setCookie("introjs_startonload", "yes", 1);
                            window.location.href = redirectURL;
                        } else {
                            intro.exit();
                        }
                        e.preventDefault();
                    }
                    break;
                default:
                    return;
            }
        }
    });
}

window.redirect_to_start_tour = function() {
    if (window.location.href.indexOf("/dashboard") > -1) {
        intro.start();
        introJsStarted = true;
    } else {
        setCookie("introjs_page", "/dashboard?tour=start", 1);
        setCookie("introjs_startonload", "yes", 1);
        window.location.href = "/dashboard?tour=start";
    }
}

window.updateHelperHTML = function() {
    if (document.introjsHelperHTML && document.introjsHelperHTML != '') {
        if ($(".introjs-helperLayer").length) {
            $(".introjs-helperLayer").html(document.introjsHelperHTML);
        } else {
            setTimeout(updateHelperHTML, 50);
        }
    }
}


// ==========================================================================================
// 
// Well-Being Snapshot Walkthrough
// 
// ==========================================================================================

window.introWBS = undefined;
window.introJsWBSStarted = false;



window.setupWalkthroughWBS = function() {
    if ($(".start-snapshot-walkthrough").length) {
        initIntroJsForWellBeingSnapshot();
        $(document).on("click",".start-snapshot-walkthrough",function(e){
          e.preventDefault();
          introWBS.start();
          introJsWBSStarted = true;
        });
        $(document).keydown(function(e) {
          if(introWBS && introJsWBSStarted) {
            switch (e.which) {
              case 37: // left
                if ($(".introjs-prevbutton").is(":visible")) {
                  introWBS.previousStep();
                  e.preventDefault();
                }
                break;
              case 39: // right
                if ($(".introjs-nextbutton").is(":visible")) {
                  introWBS.nextStep();
                  e.preventDefault();
                } else if ($(".introjs-donebutton").is(":visible")) {
                  introWBS.exit();
                  e.preventDefault();
                }
                break;
              default:
                return;
            }
          }
        });
      }
}


$(document).on('click', '.introjs-donebutton', function(e){
    if(introJsWBSStarted == true) {
        introWBS.exit();
    }
    e.preventDefault();
});


window.createStepContent = function(vimeoId, title, text, width) {
    if (typeof width == 'undefined') {
        width = 400;
    }
    var title_html = '';
    var text_html = '';
    if (typeof title != 'undefined') {
        title_html = '<h3>'+title+'</h3>';
    }
    if (typeof text != 'undefined') {
        text_html = '<div class="pb-2">'+text+'</div>';
    }
    return '<div style="width:'+width+'px;">'+title_html+text_html+'<div class="ratio ratio-16x9"><iframe src="https://player.vimeo.com/video/'+vimeoId+'" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div></div>';
}


window.initIntroJsForWellBeingSnapshot = function(){
    introWBS = introJs();
    var stepArray = [];
  
    stepArray.push({
      intro: createStepContent("777150445", i18n_string('walkthrough.introducing_the_wellbeing_snapshot', 'Introducing the Well-Being Snapshot'), i18n_string('walkthrough.learn_about_the_principles_of_the_newest_report_av', 'Learn about the principles of the newest report available in your Well-Being Index account.'),700)
    });
    stepArray.push({
      element: "#ranges-explanation-all",
      intro: createStepContent("776786710", i18n_string('walkthrough.wellbeing_continuum', 'Well-Being Continuum'), i18n_string('walkthrough.explore_4_categories_of_wellbeing_and_distress_and', 'Explore 4 categories of well-being and distress and the likelihood of positive and negative outcomes.')),
      action: 'expand_4_range_learn_more',
    });
    stepArray.push({
      element: "#chart-data-holder",
      intro: createStepContent("776786729", i18n_string('walkthrough.graph__visual_display_of_data', 'Graph - Visual Display of Data'), i18n_string('walkthrough.see_how_the_data_is_displayed_in_the_graph_area', 'See how the data is displayed in the graph area.')),
      action: 'close_4_range_learn_more',
    });
    stepArray.push({
      element: "#full-width-report-table-container",
      intro: createStepContent("776786755", i18n_string('walkthrough.data_table', 'Data Table'), i18n_string('walkthrough.see_how_the_data_is_depicted_in_the_report_table', 'See how the data is depicted in the report table.')),
    });
    stepArray.push({
      element: "#configureReportOptions",
      intro: createStepContent("776786768", i18n_string('walkthrough.report_settings_overview', 'Report Settings Overview')),
      action: 'open_report_settings_panel',
    });
    stepArray.push({
      element: "#wbs-create-your-groups",
      intro: createStepContent("776786779", i18n_string('walkthrough.report_settings__create_groups', 'Report Settings - Create Groups'), i18n_string('walkthrough.create_custom_groups_using_demographic_questions', 'Create custom groups using demographic questions.')),
      action: 'open_report_settings_panel',
      loadHelperHTMLFromElement: true,
    });
    // #wbs-report-display
    stepArray.push({
      element: "#wbs-report-display",
      intro: createStepContent("776786797", i18n_string('walkthrough.report_settings__sort_by', 'Report Settings - Sort By'), i18n_string('walkthrough.review_the_different_ways_you_can_sort_your_report', 'Review the different ways you can sort your report data.')),
      action: 'open_report_settings_panel',
      loadHelperHTMLFromElement: true,
    });
    // #wbs-report-assessments
    stepArray.push({
      element: "#wbs-report-assessments",
      intro: createStepContent("776786821", i18n_string('walkthrough.settings__date_range__assessment_type', 'Settings - Date Range & Assessment Type'), i18n_string('walkthrough.limit_the_assessments', 'Limit the assessments')),
      action: 'open_report_settings_panel',
      loadHelperHTMLFromElement: true,
    });
    // #wbs-report-comparisons
    stepArray.push({
      element: "#wbs-report-comparisons",
      intro: createStepContent("776786824", i18n_string('walkthrough.settings__compare', 'Settings - Compare'), i18n_string('walkthrough.compare_your_current_view_of_the_data_to_national_', 'Compare your current view of the data to national and organization benchmark data.')),
      action: 'open_report_settings_panel',
      loadHelperHTMLFromElement: true,
    });
    stepArray.push({
      intro: createStepContent("776786845", i18n_string('walkthrough.suggested_views', 'Suggested Views'), i18n_string('walkthrough.get_a_quick_example_of_suggested_views_to_get_acti', 'Get a quick example of suggested views to get actionable insights from your report.'),700),
      action: 'close_report_settings_panel'
    });
  
  
    introWBS.setOptions({
      steps: stepArray,
      showBullets: false,
      scrollToElement: true,
      scrollTo: 'tooltip',
      showStepNumbers: false,
      keyboardNavigation: false,
      hideNext: true,

        skipLabel: i18n_string("general.exit", "Exit"),
        doneLabel: i18n_string("general.done", "Done"),
        nextLabel: window.nextText,
        prevLabel: window.backText,
    });
  
    introWBS.onbeforechange(function(targetElement) {
      document.introjsHelperHTML = '';
      $(".introjs-helperLayer").html("");
      if(this._introItems && this._currentStep >= 0 && this._introItems[this._currentStep])
      {
        var stepObj = this._introItems[this._currentStep];
        if(stepObj.helperHTML || stepObj.loadHelperHTMLFromElement)
        {
          var helperHTMLContent = stepObj.helperHTML;
          if (stepObj.loadHelperHTMLFromElement) {
            helperHTMLContent = '<div style="overflow:hidden;">'+$(stepObj.element).prop('outerHTML')+'</div>';
          }
  
          // introjs-helperLayer  introjs-fixedTooltip
          if($(".introjs-helperLayer").length)
          {
            setTimeout(function(){
              $(".introjs-helperLayer").html(helperHTMLContent);
            },250);
          }
          else
          {
            document.introjsHelperHTML = helperHTMLContent;
            setTimeout(updateHelperHTML,250);
          }
        }
        if(stepObj.action)
        {
          if (stepObj.action == "expand_4_range_learn_more"){
            $($("#continuum-button").attr('href')).addClass('in show');
          } else if (stepObj.action == "close_4_range_learn_more") {
            $($("#continuum-button").attr('href')).removeClass('in show');
          } else if (stepObj.action == "open_report_settings_panel") {
            $(window).scrollTop(0);
            $("#configureReportOptions").css("transition","none").css("visibility", "visible").addClass("show");            
          } else if (stepObj.action == "close_report_settings_panel") {
            $("#configureReportOptions").css("transition","none").css("visibility", "hidden").removeClass("open");
            $('.introjs-tooltipbuttons').append('<a role="button" tabindex="0" class="introjs-button introjs-fullbutton introjs-donebutton" style="float: right">'+i18n_string("general.done", "Done")+'</a>');
          }
  
        }
      }
    });
    introWBS.addHints();
}



// ==========================================================================================
// 
// Reworked Admin Navigation
// 
// ==========================================================================================

window.introNN = undefined;
window.introJsNNStarted = false;



window.setupWalkthroughNewNav = function() {
    if ($(".new_admin_nav").length) {
        initIntroJsForNewNav();
        $(document).on("click",".new_admin_nav",function(e){
          e.preventDefault();
          introNN.start();
          introJsNNStarted = true;
        });
        $(document).keydown(function(e) {
          if(introWBS && introJsWBSStarted) {
            switch (e.which) {
              case 37: // left
                if ($(".introjs-prevbutton").is(":visible")) {
                    introNN.previousStep();
                  e.preventDefault();
                }
                break;
              case 39: // right
                if ($(".introjs-nextbutton").is(":visible")) {
                  introNN.nextStep();
                  e.preventDefault();
                } else if ($(".introjs-donebutton").is(":visible")) {
                  introNN.exit();
                  e.preventDefault();
                }
                break;
              default:
                return;
            }
          }
        });
    }
}


window.initIntroJsForNewNav = function(){
    introNN = introJs();
    var stepArray = [];
  
    stepArray.push({
      intro: createStepContent("", 'New Navigation', 'We reworked that clunky old nav to something super sweet...',700)
    });
    stepArray.push({
      element: "#ranges-explanation-all",
      intro: createStepContent("776786710", 'Well-Being Continuum', 'Explore 4 categories of well-being and distress and the likelihood of positive and negative outcomes.'),
      action: 'expand_4_range_learn_more',
    });

    introNN.setOptions({
        steps: stepArray,
        showBullets: false,
        scrollToElement: true,
        scrollTo: 'tooltip',
        showStepNumbers: false,
        keyboardNavigation: false,
        hideNext: true,
        skipLabel: "Exit",
      });
    
      introNN.onbeforechange(function(targetElement) {
        document.introjsHelperHTML = '';
        $(".introjs-helperLayer").html("");
        if(this._introItems && this._currentStep >= 0 && this._introItems[this._currentStep])
        {
          var stepObj = this._introItems[this._currentStep];
          if(stepObj.helperHTML || stepObj.loadHelperHTMLFromElement)
          {
            var helperHTMLContent = stepObj.helperHTML;
            if (stepObj.loadHelperHTMLFromElement) {
              helperHTMLContent = '<div style="overflow:hidden;">'+$(stepObj.element).prop('outerHTML')+'</div>';
            }
    
            // introjs-helperLayer  introjs-fixedTooltip
            if($(".introjs-helperLayer").length)
            {
              setTimeout(function(){
                $(".introjs-helperLayer").html(helperHTMLContent);
              },250);
            }
            else
            {
              document.introjsHelperHTML = helperHTMLContent;
              setTimeout(updateHelperHTML,250);
            }
          }
          if(stepObj.action)
          {
            if (stepObj.action == "expand_4_range_learn_more"){
              $($("#continuum-button").attr('href')).addClass('in show');
            } else if (stepObj.action == "close_4_range_learn_more") {
              $($("#continuum-button").attr('href')).removeClass('in show');
            } else if (stepObj.action == "open_report_settings_panel") {
              $(window).scrollTop(0);
              $("#configureReportOptions").css("transition","none").css("visibility", "visible").addClass("show");            
            } else if (stepObj.action == "close_report_settings_panel") {
              $("#configureReportOptions").css("transition","none").css("visibility", "hidden").removeClass("open");
              $('.introjs-tooltipbuttons').append('<a role="button" tabindex="0" class="introjs-button introjs-fullbutton introjs-donebutton" style="float: right">Done</a>');
            }
    
          }
        }
      });
      introNN.addHints();
}


