import { Controller } from "@hotwired/stimulus"
import { Canvg } from 'canvg';

// Connects to data-controller="report-display"
export default class extends Controller {

  // Always have the connect function first. 
  connect() {
    //console.log("Report Display Controller")
    $('#chart-data-holder').html('<div class="chart-loader"></div>')
    $('#table-data-holder').hide()

    document.report_table_download_count = 0;
    document.offcanvas = document.getElementById('configureReportOptions');
    document.bsOffcanvas = new bootstrap.Offcanvas( document.offcanvas );

    document.saved_filter_loaded = undefined;
    document.edit_saved_filter_loaded = undefined;
    this.initializeSelect2(".select2", {})

    // Ensure we have the settings panel available to us.
    if($("#report-settings-panel-form").length) {
      this.resetFilterOptions();
      this.doInitialLoad();
      this.initReportSettingsSavedFilterSorting();
    }

    window.initTooltips()
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  additionalGroupingCheckAll(event) {
    var item = event.target;
    var isChecked = item.checked;
    $("input[name='additional_grouping[]']").each(function(){
      this.checked = isChecked;
    });
  }

  array_diff_assoc (arr1) {
    var retArr = {};
    var argl = arguments.length;
    var k1 = '';
    var i = 1;
    var k = '';
    var arr = {};
  
    arr1keys: for (k1 in arr1) {
      for (i = 1; i < argl; i++) {
        arr = arguments[i];
        for (k in arr) {
          if (arr[k] === arr1[k1] && k === k1) {
            continue arr1keys;
          }
        }
        retArr[k1] = arr1[k1];
      }
    }
  
    return retArr;
  }

  checkReportSettingsPanelOverflow()
  {
    var panelDiv = document.getElementById('configureReportOptions');
    if(panelDiv)
    {
      var scrollPanelElements = panelDiv.getElementsByClassName("offcanvas-body");
      if(scrollPanelElements && scrollPanelElements[0])
      {
        var combined_element_height = $("#report-settings").height();
        combined_element_height += 40; // Padding
        if(!$("#report-settings-update-btn-container-follow").is(":visible"))
        {
          combined_element_height += 50; // Hidden button
        }

        if(combined_element_height > scrollPanelElements[0].offsetHeight)
        {
          if(!$("#report-settings-update-btn-container-sticky").is(":visible"))
          {
            $("#report-settings-update-btn-container-follow").fadeOut('fast');
            $("#report-settings-update-btn-container-sticky").fadeIn('fast');
          }
        }
        else
        {
          if(!$("#report-settings-update-btn-container-follow").is(":visible"))
          {
            $("#report-settings-update-btn-container-sticky").fadeOut('fast');
            $("#report-settings-update-btn-container-follow").fadeIn('fast');
          }
        }
      }
    }
  }

  clearReportSettings(e) {
    e.preventDefault()
    this.clearReportForReload()
    this.resetSettingsToDefault()
    $('#submit').click()
  }

  clearReportForReload() {
    $('#table-data-holder').hide()
    $('#national_comparative_data').html('');
    $('#table-data').html('');
    $('#chart-data-holder').html('<div class="chart-loader"></div>');
    $('#date-range-display').html('');
    $('#location-display').html('');
  }

  //data-action="change->report-display#date_range_change" data-range-kind="end" data-range-other="custom_start_date"
  date_range_change(e) {
    const controllerRef = this;
    let changedInput = e.currentTarget;
    if (changedInput && changedInput.dataset && changedInput.dataset.rangeKind && changedInput.dataset.rangeOther ) {
      let otherInput = document.getElementById(changedInput.dataset.rangeOther);
      if (otherInput) {
        let changedInputVal = changedInput.value;
        if (changedInput.dataset.rangeKind == "end") {
          otherInput.setAttribute("max", changedInputVal);
          if (changedInputVal < otherInput.value) {
            otherInput.value = changedInputVal;
          }
        } else { //changedInput.dataset.rangeKind == "start"
          otherInput.setAttribute("min", changedInputVal);
          if (changedInputVal > otherInput.value) {
            otherInput.value = changedInputVal;
          }
        }
      }
    }
  }

  deleteSavedFilter(e) {
    const controllerRef = this
    e.preventDefault()
    var item = e.target

    if(confirm(i18n_string("reports.chart_settings.are_you_sure_you_want_to_delete_this_report_settings_filter", "Are you sure you want to delete this report settings filter?"))) {
      var authtoken = $("#edit_saved_report_setting_" + item.dataset.id).children('input[name="authenticity_token"]').val()
    
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/reports/" + item.dataset.id + "/delete_filter", 
        type: 'DELETE', 
        success(data) {
          if(data.status == 200) {
            $(".report-settings-saved-filter").each(function(){
              if($(this).data("save_id") == data.id) {
                $(this).remove();
              }
            });
  
            // Some magic if you just deleted your currently applied filter
            var removed_saved_data = $("#report-settings-saved-filter-data"+data.id).val();
            $("#report-settings-saved-filter-data"+data.id).remove();
            document.edit_saved_filter_loaded = undefined;
            controllerRef.incrementSavedSettingsCountForTab(-1);
  
            if(document.saved_report_settings_last == removed_saved_data){
              controllerRef.resetSettingsToDefault()
              $('#submit').click()
            }
  
            $("#inner_modal_popup").modal("hide");
          }
          else {
            alert(i18n_string("reports.chart_settings.error_deleting_saved_filter", "Error deleting saved filter"));
          }
        }
      });
    }

  }

  deselectAllLocations(e) {
    e.preventDefault()
    $("#select2_locations > option").prop("selected",false);
    $("#select2_locations > option:first").prop("selected","selected");
    $("#select2_locations").trigger("change");
    this.checkReportSettingsPanelOverflow();
  }

  doInitialLoad() {
    document.initial_report_load_complete = false;

    if ($("#dev_test_tool_report_dates_enabled").length) {
      $("#date_range_select_custom").prop("checked", true);
      $("#custom_start_date").val($("#dev_test_tool_report_date_start").val());
      $("#custom_end_date").val($("#dev_test_tool_report_date_end").val());
    }

    // Make sure additional_groupings are selected before saving default load settings
    // --  Needed to add to match the submitForm function
    if($("#report_id").length && $("#report_id").val() == "resources_accessed" && $('input[name="additional_grouping[]"]').length && $('input[name="additional_grouping[]"]:checked').length == 0) {
      $.each($('input[name="additional_grouping[]"]'), function(){
        $(this).prop("checked", true)
      })
    }

    // Get the settings from the form itself.
    document.saved_report_settings_default = this.getJSONLoadSettingsString($('#report-settings-panel-form'))

    // If we have saved report settings from a previous session (saved in database)
    if( $("#saved_report_settings_last").length && $("#saved_report_settings_last").val() != "") {
      document.saved_report_settings_last = $("#saved_report_settings_last").val()

      if( JSON.parse(document.saved_report_settings_default).wbi_version != JSON.parse(document.saved_report_settings_last).wbi_version ) {
        // console.log("WBI version changed")
        document.saved_report_settings_last = document.saved_report_settings_default
      }

      if($("#saved_report_settings_last_save_id").length) {
        document.saved_filter_loaded = $("#saved_report_settings_last_save_id").val()
      }

      // Load saved settings
      this.loadSettingsFromSerializedString(document.saved_report_settings_last);

    } else {
      document.saved_report_settings_last = document.saved_report_settings_default;
    }

    // If not loading for automated report panel, then submit form :)
    if (!$("#automated-reporting-config-panel").length) {
      $('#submit').click();
    }
  }


  drawChart() {
    const controllerRef = this
    if(document.getElementById("chart-data-holder"))
    {

      var date_format = $('input[name="duration"]:checked').val()
      var data = new google.visualization.DataTable();

      if(window.chart_kind == "bar") {
        var chart = new google.visualization.ColumnChart(document.getElementById("chart-data-holder"));
      } 
      else if( window.chart_kind == "line") {
        var chart = new google.visualization.LineChart(document.getElementById("chart-data-holder"));
      }
      else if( window.chart_kind == "pie") {
        var chart = new google.visualization.PieChart(document.getElementById("chart-data-holder"));
      }

      window.chart_columns.forEach(function(value, index){
        if(Object.keys(value) == 'string') {
          data.addColumn( 'string', Object.values(value) )
        } 
        else if(Object.keys(value) == 'number') {
          data.addColumn( 'number', Object.values(value) )
        } 
        else if(Object.keys(value) == 'tooltip')  {
          data.addColumn( {type: 'string', role: 'tooltip', p: {'html': true}} )
        }
        else if(Object.keys(value) == 'style') {
          data.addColumn({type: 'string', role: 'style'});
        }
        else if(Object.keys(value) == 'date') {
          data.addColumn( 'date', Object.values(value) )
        }
      })

      if(window.chart_kind == "line" || window.chart_kind == "bar") {
        window.chart_data.forEach(function(value){
          if(window.chart_kind == "line") {

            // this.generateDateForReport isn't working here :/ so needed to be inline
            var date = value[0].toString()

            var year = date.substring(0,4);
            var month = date.substring(4,6);
            var day = ''
            
            if(date.length > 6)
            {
              day = date.substring(6,8);
            }

            if(date_format == 'week'){
              date = new Date(year, 0,  1 + (month - 1) * 7);
            }
            else if(date_format == 'month') {
              date = new Date(year, (month-1));
            }
            else if(date_format == 'year') {
              date = new Date(year);
            }
            else if(date_format == 'day') {
              date = new Date(year, (month-1), day);
            }

            value[0] = date
            data.addRow(value)
          } else {
            data.addRow(value)
          }  
        })
      }

      if(window.chart_kind == "pie") {
        if(window.chart_date.length == 2) {
          // Comparison Pie Charts
          chart_data = window.chart_data

          var arr = [];

          $.each(chart_data[0], function(key, val){
            arr.push($.map(val, function(el) { return el }));
          });
          data.addRows(arr);

          var first_data_set = data;
          var second_data_set = first_data_set.clone();
          second_data_set.removeRows(0, 10000);
          var arr2 = [];
          $.each(chart_data[1], function(key, val){
            arr2.push($.map(val, function(el) { return el }));
          });
          second_data_set.addRows(arr2);
          data = chart.computeDiff(first_data_set, second_data_set);
        } else {

          data.addRows(window.chart_data)
        }
      }

      var options = window.chart_options

      // window.chart_data.forEach(function(value){
      if(window.chart_kind == "line" && window.chart_data && window.chart_data.length == 1) {
        // console.log(options['hAxis']);
        options['hAxis']['ticks'] = [];
      }

      // If we have a high level of distress line present.
      if(window.distress_line_val != undefined) {
        let high_level_of_distress_text = i18n_string("reports.high_level_of_distress", "High Level of Distress");
        data.addColumn( 'number', high_level_of_distress_text)

        var colCount = data.getNumberOfColumns();
        var rowCount = data.getNumberOfRows();
        for(var zz=0;zz<rowCount;zz++)
        {
          data.setCell(zz, colCount-1, window.distress_line_val);
        }

        var seriesNum = -1;
        for(var yy=0;yy<colCount;yy++)
        {
          var coltype = data.getColumnType(yy);
          if(coltype == "number")
          {
            seriesNum++;
          }
        }
        options.seriesType = 'bars';
        options.series = {};
        options.series[seriesNum] = {
            type: "steppedArea",
            color: '#FF0000',
            visibleInLegend: false,
            areaOpacity: 0,
            enableInteractivity: false
        };
      }

      window.cached_chart_data = [window.chart_kind, data.toJSON(), JSON.stringify(options)];

      google.visualization.events.addListener(chart, 'ready', function() {
        // Download report
        $("#image_data").val(chart.getImageURI());
      });

      chart.draw(data, options);
    }
  }

  getCurrentReportFilterSettingsForDisplay() {
    return window.getCurrentReportFilterSettingsForDisplay();
  }

  getCurrentReportFilterSettingsForDisplayHTML()
  {
    var settings_list = this.getCurrentReportFilterSettingsForDisplay();
    var return_html = "";
    if(settings_list.length > 0)
    {
      return_html += '<h2>'+i18n_string("reports.chart_settings.title", "Chart Settings")+'</h2>';
      for(var i=0;i<settings_list.length;i++){
        return_html += '<p class="setting-print">';
        return_html += '<strong>'+settings_list[i][0]+'</strong><br />';
        return_html += settings_list[i][1];
        return_html += '</p>';
      }
    }
    return return_html;
  }

  getJSONLoadSettingsString($formObj) {
    var settings_data = $formObj.serializeArray();
    var final_data_array = {};
    var index_array_counter = {};
    for(var i=0;i<settings_data.length;i++){
      var name = settings_data[i].name;
      if(name != 'authenticity_token') {
        if(name.indexOf("[]") != -1){
          if(!index_array_counter[name]){
            index_array_counter[name] = 0;
          }
          var new_temp_name = name.replace("[]","["+index_array_counter[name]+"]");
          index_array_counter[name]++;
          name = new_temp_name;
        }
        final_data_array[name] = settings_data[i].value;
      }
    }
    return JSON.stringify(final_data_array);
  }

  incrementSavedSettingsCountForTab(amountVal){
    var current_html = $("#report-saved-tab").html();
    // console.log(current_html);
    var current_val = 0;
    if(current_html.indexOf("(") != -1) {
      var regExp = /\(([^)]+)\)/;
      var matches = regExp.exec(current_html);
      // console.log(matches[1]);
      if(Number(matches[1]) > 0){
        current_val = Number(matches[1]);
      }
    }
    current_val += amountVal;
    $("#report-saved-tab").html(i18n_string("reports.saved_settings", "Saved Settings")+" ("+current_val+")");
    if(current_val > 1){
      this.initReportSettingsSavedFilterSorting();
    }
  }

  initializeSelect2(selector, options) {
    $(selector).each(function () {
      var parentElement = $(this).closest(".select2-wrapper-div");
      if (parentElement.length) {
        options.dropdownParent = parentElement;
      }
      $(this).select2(options);
    });
  }

  initReportSettingsSavedFilterSorting(){
    if ($( '#report-settings-saved-filter-list-group' ).data( 'sortable' )) {
      $("#report-settings-saved-filter-list-group").sortable("destroy");
    }
    $( "#report-settings-saved-filter-list-group" ).sortable({
      handle: ".drag-handle",
      update: function( event, ui ) {
        var save_id_order = [];
        $('#report-settings-saved-filter-list-group .report-settings-saved-filter').each(function(idx) {
          save_id_order.push($(this).data("save_id"));
        });
        // console.log("save_id_order="+save_id_order.join(","));
        // Save ordering to server
        var formData = {positions: save_id_order};
        $.ajax({
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          url: "/reports/save_report_setting_order",
          data: formData,
          type: "POST",
          success(dataObj) {
            if(dataObj.success){
              $("#report-settings-saved-list-sort-button-container").html('<span class="text-success">'+i18n_string("reports.chart_settings.order_saved", "Order Saved")+'</span>');
            }else if(dataObj.error){
              $("#report-settings-saved-list-sort-button-container").html('<span class="text-danger">'+dataObj.error+'</span>');
            }
            setTimeout(function(){
              $("#report-settings-saved-list-sort-button-container").html('&nbsp;');
            },2500);
          }
        });

      }
    });
  }


  loadSavedReportSettings(e) {
    e.preventDefault();

    var item = e.target

    var $parentContainer = $(item).closest(".report-settings-saved-filter");
    var save_id = $parentContainer.data("save_id");

    if(save_id) {
      document.saved_filter_loaded = save_id;
      var settings_content = $("#report-settings-saved-filter-data" + save_id).val();
      // console.log(settings_content);
      $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success");
      $parentContainer.addClass("list-group-item-success");

      this.loadSettingsFromSerializedString(settings_content);
      $('#submit').click()

      document.bsOffcanvas.hide()
    }
  }

  loadSettingsFromSerializedString(serializedDataString){
    var dataParsed = JSON.parse(serializedDataString);

    if ($("#dev_test_tool_report_dates_enabled").length) {
      dataParsed['start_date'] = $("#dev_test_tool_report_date_start").val();
      dataParsed['end_date'] = $("#dev_test_tool_report_date_end").val();
      dataParsed['date_range_select'] = '0';
    }

    var dataKeys = Object.keys(dataParsed);
    // UNCHECK CHECKBOXES/MULTI-SELECTs
    for(var i=0;i<dataKeys.length;i++){
      var name = dataKeys[i];
      var value = dataParsed[name];
      if(name.indexOf('[') == -1 || $("[name='"+name+"']").length > 0)
      {
        var $element = $("[name='"+name+"']");
        var inputType = $element.prop("type");
        if(inputType == "checkbox") {
          $element.prop("checked",false);
        } else if(inputType == "select-multiple") {
          $element.children("option").prop("selected", false);
        }
      }
      else if(name.indexOf('[') != -1)
      {
        // Multi-select selectbox or checkbox array
        var nameParts = name.split("[");
        name = nameParts[0]+"[]";
        var $element = $("[name='"+name+"']");
        if($element.length > 0)
        {
          var inputType = $element.prop("type");
          if(inputType == "checkbox") {
            $element.prop("checked",false);
          } else if(inputType == "select-multiple") {
            $element.children("option").prop("selected", false);
          }
  
        }
      }
    }
    // SELECT NEW VALUES
    var nonExistingIndexes = [];
    for(var i=0;i<dataKeys.length;i++){
      var name = dataKeys[i];
      var value = dataParsed[name];
      if(name.indexOf('[') == -1 || $("[name='"+name+"']").length > 0)
      {
        var $element = $("[name='"+name+"']");
        var inputType = $element.prop("type");
        if(inputType == "radio") {
          $("[name='"+name+"'][value='"+value+"']").click();
        } else if(inputType == "checkbox") {
          $element.each(function(){
            $(this).prop("checked",true);
          });
        } else {
          $element.val(value);
        }
  
        if($element.hasClass("select2-hidden-accessible")){
          $element.trigger('change.select2');
        }
      }
      else if(name.indexOf('[') != -1)
      {
        // Multi-select selectbox or checkbox array
        var nameParts = name.split("[");
        name = nameParts[0]+"[]";
        var $element = $("[name='"+name+"']");
        if($element.length > 0)
        {
          var inputType = $element.prop("type");
          if(inputType == "radio") {
            $("[name='"+name+"'][value='"+value+"']").click();
          } else if(inputType == "checkbox") {
            $element.each(function(){
              if($(this).val() == value) {
                $(this).prop("checked", true);
              }
            });
          } else if(inputType == "select-multiple") {
            $element.children("option[value='" + value + "']").prop("selected", true);
          } else if ($element.length == 1){
            $element.val(value);
          }
  
          if($element.hasClass("select2-hidden-accessible")){
            $element.trigger('change.select2');
          }
  
        }
        else
        {
          nonExistingIndexes.push(i);
        }
      }
    }
    // TODO: Handle dynamic fields that may not be on the form yet :)
    // -- Temporary variables can be put in id="report-settings-additional-grouping-always-hidden"
    $("#report-settings-additional-grouping-always-hidden").html("");
    // if(nonExistingIndexes.length > 0){
    //   for(var j=0;j<nonExistingIndexes.length;j++) {
    //     var i = nonExistingIndexes[j];
    //     var name = dataKeys[i];
    //     var value = dataParsed[name];
    //     var nameParts = name.split("[");
    //     name = nameParts[0]+"[]";
    //     var $temp_hidden = $('<input type="hidden" name="'+name+'" class="temporary-additional-grouping-input" />');
    //     $temp_hidden.val(value);
    //     $("#report-settings-additional-grouping-always-hidden").append($temp_hidden);
    //   }
    // }
  }

  reportSettingsCheckForChangeFromDefault() {

    var array1 = JSON.parse(document.saved_report_settings_default);
    var array2 = JSON.parse(document.saved_report_settings_last);

    // console.log("Saved Report Settings Default", array1);
    // console.log("Saved Report Settings Last", array2);

    if($("#dynamic-additional-grouping-div").length)
    {
      var temp_array2 = {};
      var arrayKeys = Object.keys(array2);
      for(var i=0;i<arrayKeys.length;i++){
        var currentName = arrayKeys[i];
        if(currentName.indexOf("additional_grouping[") == -1){
          temp_array2[currentName] = array2[currentName];
        }
      }
      array2 = temp_array2;

    }
    var comparison = this.array_diff_assoc(array1, array2);
    var comparison2 = this.array_diff_assoc(array2, array1);

    // console.log("Comparison 1", comparison);
    // console.log("Comparison 2", comparison2);

    if(Object.keys(comparison).length > 0 || Object.keys(comparison2).length > 0){
      // Notice on "Configure Report" button
      // Notice in header area to reset
      // Notice in settings panel to reset
      // Notice in settings panel to save as "Saved Configuration"
      $(".report-settings-filter-applied-notice").show();
      // console.log("Save Filter Loaded:" + document.saved_filter_loaded)

      if(document.saved_filter_loaded && document.saved_filter_loaded > 0)
      {
        // console.log("A")
        $(".report-settings-filter-save-btn").hide();
        $(".report-settings-filter-save-as-new-btn").show();
        // Check to see if the saved_filter_loaded differs from the current last filter
        $(".report-settings-filter-update-btn").show();
      }
      else
      {
        // console.log("B")
        $(".report-settings-filter-update-btn").hide();
        $(".report-settings-filter-save-as-new-btn").hide();
        $(".report-settings-filter-save-btn").show();
      }
    }else{
      // console.log("C")
      $(".report-settings-filter-applied-notice").hide();
      $(".report-settings-filter-save-btn").hide();
      $(".report-settings-filter-update-btn").hide();
      $(".report-settings-filter-save-as-new-btn").hide();
    }
    this.checkReportSettingsPanelOverflow();
  } 

  resetFilterOptions() {
    // Used to set the initial load of filter options
    // If possible use the label for attribute on the buttons
    if($('[name="display_data"]').length) { $('[name="display_data"]:first').click() }
    if($('#chart_settings_style').length) { $('#chart_settings_style').children('div').children().first('input').click() }
    if($('#chart_settings_period').length) {
      if ($("#dev_test_tool_report_dates_enabled").length) {
        $('[for="date_range_select_custom"]').click();
        $("#custom_start_date").val($("#dev_test_tool_report_date_start").val());
        $("#custom_end_date").val($("#dev_test_tool_report_date_end").val());
      } else {
        $('[for="date_range_select_all"]').click();
      }
    }
    if($('#chart_settings_period_email').length) { $('[for="date_range_select_1"]').click() }
    if($('#chart_layout_duration').length) { $('[for="chart_duration_month"]').click() }
    if($("#custom_date_range_period").length) { 
      var min = $('#custom_start_date').attr('min'); 
      $('#custom_start_date').val( min ); 
      $('#custom_end_date').val( new Date().toISOString().slice(0, 10) );
    }
    if($("#compare_to").length) { $('[for="compare_date_range_select_none"]').click() }
    if($("#compare_date_range_select").length) { 
      var min = $('#compare_start_date').attr('min');
      $('#compare_start_date').val( min ); 
      $('#compare_end_date').val( new Date().toISOString().slice(0, 10) );
    }
    if($("#locations.select-all-by-default").length) { $("#select_all").click(); }
    if($("#locations #facility_grouping").length) { $('#facility_grouping').prop("selectedIndex", 0).trigger("change") }
    if($("#show_subspecialty").length){ $('#show_subspecialty').prop("selectedIndex", 0).trigger("change"); this.toggleSpecialty(null) }
    if($("#chart_settings_specialties").length && !$("#adv_report_id").length){ $.each($('input[name="additional_grouping[]"]'), function(){ $(this).prop("checked", false) }) }
    $('#national_comparative_data').html('');
  }

  resetSettingsToDefault() {
    if(document.saved_report_settings_default){
      document.saved_filter_loaded = undefined;
      $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success");
      this.loadSettingsFromSerializedString(document.saved_report_settings_default);
    }
  }

  resourceCheckAll(event) {
    var item = event.target;
    var isChecked = item.checked;
    $("input[name='resource[]']").each(function(){
      this.checked = isChecked;
    });
  }

  saveLastReportSettings(){
    // Do not save the "last" settings when running a report through automation
    if ($("#skip_report_settings_save").length) {
      return;
    }
    const controllerRef = this
    var settings_last_data = $("#report-settings-panel-form").serializeArray()

    var formData = { settings_data: settings_last_data, save_for: "last" }
    $.post("/reports/save_settings", formData, function(returnDataStr) {
      if(returnDataStr.status == 200) {
        document.saved_report_settings_last = controllerRef.getJSONLoadSettingsString($("#report-settings-panel-form"))
        controllerRef.reportSettingsCheckForChangeFromDefault()
      } else {
        alert(i18n_string("reports.error_loading_report", "Error loading report"));
      }
    });
  }

  saveReportFilter(e) {
    e.preventDefault();
    const controllerRef = this

    var save_for = "account";
    if($("#save_report_settings_modal_allow_org").prop("checked")){
      save_for = "customer_sub_account";
    }

    var save_report_id = $("#save_report_id").val();
    var save_report_name = $("#saved_report_setting_report_name").val();
    var settings_data = $("#report-settings-panel-form").serializeArray();
    var formData = {settings_data: settings_data, save_for: save_for, save_report_name: save_report_name};

    if(save_report_id && save_report_id > 0){
      // Update
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: "/reports/" + save_report_id + "/update_filter", 
        data: { save_report_name: save_report_name, allow_org: $("#save_report_settings_modal_allow_org").is(":checked") }, 
        type: "PATCH",
        success(data) {
          if(data.status == 200) {
            // Update list item name and data
            var $item = $(".report-settings-saved-filter").filter(function(){
              return $(this).data("save_id") == data.data.id;
            });
  
            $item.data("save_for", data.data.save_for);
            $item.data("save_report_name", data.data.report_name);
            $item.find(".report-settings-saved-filter-load-btn").html(data.data.report_name);

            document.edit_saved_filter_loaded = undefined;
            $("#saveReportSettingsModal").modal("hide");
          }
        }
      });
    } else {
      // Save as new
      $.post( $("#new_saved_report_setting").attr('action'), formData, function(returnDataObj) {

        if(returnDataObj.status == 200) {
          $(".report-settings-saved-filter.list-group-item-success").removeClass("list-group-item-success"); // Remove currently selected item if there is one
          document.saved_report_settings_last = returnDataObj.data.content;
          // Insert into list
          $("#report-settings-saved-filter-none-message").hide();
          var savedItemHTML = '<div class="list-group-item report-settings-saved-filter justify-content-between list-group-item-success" data-save_id="' + returnDataObj.data.id + '" data-save_for="' + returnDataObj.data.save_for + '" data-save_report_name="' + returnDataObj.data.report_name + '"></div>';
          var $savedItemHTMLObj = $(savedItemHTML);

          $("#report-saved .list-group").append($savedItemHTMLObj);

          var savedLinkHTML = '<div><span class="drag-handle fas fa-grip-vertical ui-sortable-handle"></span> <a href="#" class="report-settings-saved-filter-load-btn" data-action="click->report-display#loadSavedReportSettings">'+ $('<div />').text(returnDataObj.data.report_name).html() +'</a></div> <a href="/reports/' + returnDataObj.data.id + '/edit_filter" class="text-success report-settings-saved-filter-edit-btn" data-turbo-frame="inner_modal" data-action="click->modal#loadInnerModal" data-turbo="true" data-id="' + returnDataObj.data.id + '" data-modalSize="modal-md"><em class="fas fa-edit"></em></a>';
          $savedItemHTMLObj.html(savedLinkHTML);

          var $savedInputObj = $('<input type="hidden" id="report-settings-saved-filter-data'+returnDataObj.data.id+'" class="report-settings-saved-filter-data" />');
          $savedInputObj.val(returnDataObj.data.content);
          $("body").append($savedInputObj);

          // Flag update
          document.saved_filter_loaded = returnDataObj.data.id

          // Do check for alerts/notices
          controllerRef.incrementSavedSettingsCountForTab(1);
          controllerRef.reportSettingsCheckForChangeFromDefault();
          $("#system_modal_popup").modal("hide");
        } else {
          alert(i18n_string("reports.chart_settings.error_saving_report_filter", "Error saving report filter"));
        }
      });
    }

  }

  selectAllLocations(e) {
    e.preventDefault()
    $("#select2_locations > option").prop("selected","selected");
    $("#select2_locations").trigger("change");
    this.checkReportSettingsPanelOverflow();
  }

  submitForm(e) {
    if (!window.load_report_timeout) {
      window.load_report_timeout = 1500;
    } else {
      window.load_report_timeout = 50;
    }
    window.cached_chart_data = undefined;
    $('#chart-data-holder').show().html('<div class="chart-loader"></div>')
    $('#table-data-holder').hide()

    const controllerRef = this
    e.preventDefault()
    this.chart_data = ''

    this.clearReportForReload()

    if($('input[name="additional_grouping[]"]:checked').length == 0) {
      $.each($('input[name="additional_grouping[]"]'), function(){
        $(this).prop("checked", true)
      })
    }

    var form = e.target
    var form_data = $(form).serialize()

    document.bsOffcanvas.hide()
    document.offcanvas.addEventListener('hidden.bs.offcanvas', function () {
      // console.log("CLOSED")
    });
    setTimeout(() => {
      $.ajax({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        url: report_url, 
        data: form_data,
        type: 'POST', 
        async: true,
        success(data) {
          if(data.status == 200) {
            if(data.date.length == 1) {
              $("#date-range-display").html( data.date[0][2] );
            } else if(data.date.length == 2) {
              let and_text = i18n_string("general.and", "and");
              $("#date-range-display").html( data.date[0][2] + ' '+and_text+' ' +  data.date[1][2] );
            }

            $('#national_comparative_data').html( data.chart_data.national_report_label );

            if(data.chart_data.report_name) {
              $("#report-name-holder").html(data.chart_data.report_name)
            }

            $('#location-display').html( data.account_names )
            // $("#data-first-collected").html(data.first_data_collected)
            
            $("#table-data").html( data.chart_data.report_table_data)

            // $("#data-first-collected").show()
            $('#table-data-holder').show()

            if(data.chart_data.chart_data == undefined) {
              $('#chart-data-holder').hide()
            }

            // Set download report data
            $('#export_chart_title').val( $("#report-name-holder").html() )
            $("#chart_title_dates").val( $('#date-range-display').html() )
            $("#chart_title_locations").val( $("#location-display").html() )
            $("#export_chart_desc").val( $("#chart-description-holder").html() )
            // $("#export_chart_desc_dynamic").val( $("#").html() )
            // $('#export_chart_legend').val( $("#").html() )
            $("#export_chart_total_text").val( $("#table-data-holder").html() )
            // $("#export_chart_filter_settings").val( $("#").html() )



            // when setting variables that need to be passed to other functions from within an ajax response, use window. or document. instead of this.
            window.chart_date = data.date
            window.chart_data = data.chart_data.chart_data
            window.chart_options = data.chart_data.chart_options
            window.chart_columns = data.chart_data.chart_columns
            window.chart_kind = data.chart_data.chart_kind
            window.distress_line_val = data.chart_data.distress_line_val

            load_google_charts();
            add_google_chart_onload_callback( controllerRef.drawChart );

            // Save as last settings -- Only after the inital load is complete
            if(document.initial_report_load_complete) {
              controllerRef.saveLastReportSettings();
            } else {
              document.initial_report_load_complete = true
              controllerRef.reportSettingsCheckForChangeFromDefault()
            }

            window.initTooltips()
              
          } else {
            alert(i18n_string("reports.error_loading_report", "Error loading report"));
          }
        }
      });
    }, window.load_report_timeout);
  }


  submitFormNoOptions(e) {
    window.cached_chart_data = undefined;
    const controllerRef = this
    e.preventDefault()
    this.chart_data = ''

    this.clearReportForReload()

    var form = e.target
    var form_data = $(form).serialize()

    $.ajax({
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      url: report_url, 
      data: form_data,
      type: 'POST', 
      async: false,
      success(data) {
        if(data.status == 200) {
          if(data.date.length == 1) {
            $("#date-range-display").html( data.date[0][2] );
          } else if(data.date.length == 2) {
            let and_text = i18n_string("general.and", "and");
            $("#date-range-display").html( data.date[0][2] + ' '+and_text+' ' +  data.date[1][2] );
          }

          $('#location-display').html( data.account_names )
          
          $("#table-data").html( data.chart_data.report_table_data)

          // $("#data-first-collected").show()
          $('#table-data-holder').show()

          // Set download report data
          // $('#export_chart_title').val( $("#report-name-holder").html() )
          // $("#chart_title_dates").val( $('#date-range-display').html() )
          // $("#chart_title_locations").val( $("#location-display").html() )
          // $("#export_chart_desc").val( $("#chart-description-holder").html() )
          // $("#export_chart_desc_dynamic").val( $("#").html() )
          // $('#export_chart_legend').val( $("#").html() )
          // $("#export_chart_total_text").val( $("#table-data-holder").html() )
          // $("#export_chart_filter_settings").val( $("#").html() )



          // when setting variables that need to be passed to other functions from within an ajax response, use window. or document. instead of this.
          window.chart_data = data.chart_data.chart_data
          window.chart_options = data.chart_data.chart_options
          window.chart_columns = data.chart_data.chart_columns
          window.chart_kind = data.chart_data.chart_kind
          // window.distress_line_val = data.chart_data.distress_line_val

          load_google_charts();
          add_google_chart_onload_callback( controllerRef.drawChart );

          // Save as last settings -- Only after the inital load is complete
          // if(document.initial_report_load_complete) {
          //   controllerRef.saveLastReportSettings();
          // } else {
          //   document.initial_report_load_complete = true
          //   controllerRef.reportSettingsCheckForChangeFromDefault()
          // }
            
        } else {
          alert(i18n_string("reports.error_loading_report", "Error loading report"));
        }
      }
    });
  }

  

  toggleCompareDates(event) {
    var item = event.target
    if(item.value == 'custom') {
      $('#compare_date_range_select').removeClass('hidden');
    } else {
      $('#compare_date_range_select').addClass('hidden');
    }
    this.checkReportSettingsPanelOverflow();
  }

  toggleDisplayDataType(event) {
    var sel_display_data;
    if($('input[name="display_data"]').attr("type") == "radio")
    {
      sel_display_data = $('input[name="display_data"]:checked').val();
    }
    else if($('input[name="display_data"]').attr("type") == "hidden")
    {
      sel_display_data = $('input[name="display_data"]').val();
    }
    if (sel_display_data == 'mean') {
      $("#high_level_of_distress").show();
      $("#chart_assessments").show();
      $('[for="chart_type_line"]').show();
      $('#chart_resources_accessed').addClass("hidden").hide();
    } else if (sel_display_data == 'high_distress') {
      $("#high_level_of_distress").hide();
      $("#chart_assessments").show();
      $('[for="chart_type_line"]').show();
      $('#chart_resources_accessed').addClass("hidden").hide();
    } else if (sel_display_data == 'assessments') {
      $("#high_level_of_distress").hide();
      $('#chart_resources_accessed').addClass("hidden").hide();
      $("#chart_assessments").hide();
      $('[for="chart_type_line"]').hide();
      if(!$("#chart_type_bar").prop("checked")){
        // $("#chart_type_radio_bar").prop("checked", true);
        $("#chart_type_bar").click();
      }
    } else if (sel_display_data == 'resources') {
      $('#chart_resources_accessed').removeClass("hidden").show();
      $("#high_level_of_distress").hide();
      $("#chart_assessments").hide();
      $('[for="chart_type_line"]').hide();
      if(!$("#chart_type_bar").prop("checked")){
        // $("#chart_type_radio_bar").prop("checked", true);
        $("#chart_type_bar").click();
      }
    }
    if (sel_display_data != "resources") {
      $("#choose-resource-error-holder").html("").hide()
      // Uncheck resource checkboxes
      $('input[name="resource[]"]').each(function(){
        this.checked = false;
      });
    }
    this.checkReportSettingsPanelOverflow();
  }

  togglePeriod(event) {
    var item = event.target
    var bar_chart = false
    var pie_chart = false
    if( $('input[name="chart_type"]:checked').val() == 'bar') {
      bar_chart = true
    }
    if( $('input[name="chart_type"]:checked').val() == 'pie') {
      pie_chart = true
    }

    if(item.value == -1) {
      $('#compare_to').addClass("hidden");
      // Make sure "Compare To" is also updated to "None"
      $('#compare_date_range_select').addClass('hidden');
      $('[for="compare_date_range_select_none"]').click()
    } else {
      if(bar_chart == true) {
        $("#compare_to").removeClass("hidden");
      }
      if(pie_chart == true) {
        $("#compare_to").removeClass("hidden");
      }
    }

    if(item.value == 0) {
      $('#custom_date_range_period').removeClass('hidden');
    } else {
      $('#custom_date_range_period').addClass('hidden');
    }
    this.checkReportSettingsPanelOverflow();
  }

  toggleSpecialty(event) {
    if(event != null) {
      var item = event.target
      $('.additional-grouping-item-div').addClass("hidden");
      $('.additional-grouping-item-div').children().children('input').prop("checked", false);
      if(item.value == '') {
        $('[data-parent="_top"]').removeClass("hidden")
        $('[data-parent="_top"]').children().children('input').prop("checked", true);
      } else {
        $('[data-parent="' + item.value + '"]').removeClass("hidden")
        $('[data-parent="' + item.value + '"]').children().children('input').prop("checked", true);
      }
    } else {
      $('.additional-grouping-item-div').addClass("hidden")
      $('.additional-grouping-item-div').children().children('input').prop("checked", false);

      $('[data-parent="_top"]').removeClass("hidden")
      $('[data-parent="_top"]').children().children('input').prop("checked", true);
    }
    this.checkReportSettingsPanelOverflow();
  }

  toggleStyle(event) {
    var item = event.target
    if(item.value == 'line') {
      $('#chart_layout_duration').removeClass('hidden');
      $('#compare_to').addClass("hidden");
      $('.report-line-only-div').removeClass("hidden")
      $('.report-bar-only-div').addClass("hidden")
      $('.report-pie-only-div').addClass("hidden")
      $('#additional_grouping_options').removeClass('hidden')
      // Default checking for the select all checkbox
      if ($("#select-all-options-checkbox").length) {
        let all_checked = true;
        $("input[name='additional_grouping[]']").each(function () {
          if (!this.checked) {
            all_checked = false;
          }
        });
        $("#select-all-options-checkbox").prop("checked", all_checked);
      }
    } else {
      $("#chart_layout_duration").addClass('hidden');
      $('#additional_grouping_options').addClass('hidden')
      if( $('input[name="date_range_select"]:checked').val() != -1) {
        $('#compare_to').removeClass("hidden");
      }
      if(item.value == 'bar') {
        $('.report-line-only-div').addClass("hidden")
        $('.report-bar-only-div').removeClass('hidden')
      }
      if(item.value == 'pie') {
        $('.report-line-only-div').addClass("hidden")
        $('.report-pie-only-div').removeClass('hidden')
      }
    }
    this.checkReportSettingsPanelOverflow();
  }
  

}
